import { ACTION_TYPE } from './constants';
import { ActorType } from './actor';
import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import * as applicationActions from 'redux/modules/App/actions';
import * as partnerLoginActions from 'redux/modules/PartnerLogin/actions';

export const startSession = (config) => ({
  type: ACTION_TYPE.START_SESSION,
  ...config,
});

export const startPartnerSession = (
  application,
  appointmentCode,
  name,
  id,
  isRemote = true
) =>
  startSession({
    application,
    appointmentCode,
    actor: { type: ActorType.PARTNER, name, id, isRemote },
  });

export const startCustomerSession = (appointmentCode) =>
  startSession({
    appointmentCode,
    actor: { type: ActorType.CUSTOMER },
  });

export const startSupportingSession = (appointmentCode, name, id) =>
  startSession({
    appointmentCode,
    actor: { type: ActorType.SUPPORTING_PARTNER, name, id },
  });

export const endSession = () => ({
  type: ACTION_TYPE.END_SESSION,
});

export const snackbarClose = () => ({
  type: ACTION_TYPE.SNACKBAR_CLOSE,
});

export const passControl = () => ({
  type: ACTION_TYPE.PASS_CONTROL,
});

export const takeControl = () => ({
  type: ACTION_TYPE.TAKE_CONTROL,
});

export const requestControl = () => ({
  type: ACTION_TYPE.REQUEST_CONTROL,
});

export const acceptRequestControl = () => ({
  type: ACTION_TYPE.REQUEST_CONTROL_ACCEPT,
});

export const dismissRequestControl = () => ({
  type: ACTION_TYPE.REQUEST_CONTROL_DISMISS,
});

export const acceptConnection = () => ({
  type: ACTION_TYPE.ACCEPT_CONNECTION,
});

export const rejectConnection = () => ({
  type: ACTION_TYPE.REJECT_CONNECTION,
});

export const sessionUpdate = (update) => ({
  type: ACTION_TYPE.SESSION_UPDATE,
  state: update,
});

export const stateUpdateReceived = (state, diff) => ({
  type: ACTION_TYPE.STATE_UPDATE_RECEIVED,
  state,
  diff,
});

export const remoteApplicationReceived = (
  token,
  applicationId,
  appointmentCode,
  partnerId
) =>
  applicationActions.createRemoteApplicationSuccess(
    token,
    applicationId,
    appointmentCode,
    partnerId
  );

export const remoteApplicationSupportingUpdate = (supportingPartnerId) =>
  applicationActions.putRemoteApplicationSupportingRequest(supportingPartnerId);

export const setSupportingPartner = (supportingPartnerId) =>
  partnerLoginActions.setSupportingId(supportingPartnerId);

export const remoteSessionError = (message) => openErrorGlobalDialog(message);

export const setPreviousControl = (control) => ({
  type: ACTION_TYPE.SET_PREVIOUS_CONTROL,
  control,
});

export const setPreviousPageSecure = (secure) => ({
  type: ACTION_TYPE.SET_PREVIOUS_PAGE_SECURE,
  secure,
});

export const signalSecurePage = (url) => ({
  type: ACTION_TYPE.SECURE_PAGE,
  url,
});
