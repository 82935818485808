import immutablediff from 'immutablediff';
import immutablepatch from 'immutablepatch';

const keysToReplace = [[]];

const differ = (oldState, newState, filterKeys = keysToReplace) => {
  let compareBase = oldState;
  filterKeys.forEach((path) => {
    if (!compareBase.getIn(path).equals(newState.getIn(path))) {
      compareBase = compareBase.setIn(path, null);
    }
  });

  return immutablediff(compareBase, newState);
};

export { differ, immutablepatch as patcher };
