import PartnerWithCustomerHandshake from './partnerWithCustomerHandshake';
import PartnerWithSupportingHandshake from './partnerWithSupportingHandshake';
import CustomerWithPartnerHandshake from './customerWithPartnerHandshake';
import SupportingWithPartnerHandshake from './supportingHandshake';
import PartnerWithCustomerAndSupportingPartnerHandshake from './partnerWithCustomerAndSupportingPartner';

export default class {
  static handle(actor, ...args) {
    switch (true) {
      case actor.isCustomer(): {
        const pHandshake = new CustomerWithPartnerHandshake(actor, ...args);
        pHandshake.handle();
        return;
      }
      case actor.isPartner(): {
        if (actor.isRemote) {
          const cHandshake = new PartnerWithCustomerHandshake(actor, ...args);
          cHandshake.handle();
        } else {
          const hrHandshake = new PartnerWithCustomerAndSupportingPartnerHandshake(
            actor,
            ...args
          );
          hrHandshake.handle();
        }

        const spHandshake = new PartnerWithSupportingHandshake(actor, ...args);
        spHandshake.handle();
        return;
      }
      case actor.isSupportingPartner(): {
        const pHandshake = new SupportingWithPartnerHandshake(actor, ...args);
        pHandshake.handle();
        return;
      }
      default:
        throw new Error(
          'HandshakeHandler::handle could not create instance of SecurityContext for given actor'
        );
    }
  }
}
