import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/modules/Shared/Button';
import { useIsButtonDisabled } from './useIsButtonDisabled';

function RestrictedButton({ label, dataTestId = '', loading, ...props }) {
  const disabled = useIsButtonDisabled() || props.disabled;

  return (
    <Button
      data-test-id={dataTestId}
      disabled={disabled}
      loading={loading}
      {...props}
    >
      {label}
    </Button>
  );
}

RestrictedButton.propTypes = {
  label: PropTypes.node.isRequired,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default RestrictedButton;
