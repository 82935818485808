export class SessionStorage {
  static storage = window.sessionStorage;

  static stateKey = '::remote-session::state';
  static securityContextKey = '::remote-session::security-context';

  static saveState(data) {
    SessionStorage.storage.setItem(
      SessionStorage.stateKey,
      JSON.stringify(data)
    );
  }

  static loadState() {
    const data = SessionStorage.storage.getItem(SessionStorage.stateKey);

    try {
      if (data) {
        return JSON.parse(data);
      }
    } catch (err) {
      return null;
    }

    return null;
  }

  static saveSecurityContext(data) {
    let storedData;
    try {
      storedData =
        JSON.parse(
          SessionStorage.storage.getItem(SessionStorage.securityContextKey)
        ) || {};
    } catch (err) {
      storedData = {};
    }

    SessionStorage.storage.setItem(
      SessionStorage.securityContextKey,
      JSON.stringify({ ...storedData, ...data })
    );
  }

  static loadSecurityContext() {
    const data = SessionStorage.storage.getItem(
      SessionStorage.securityContextKey
    );

    try {
      if (data) {
        return JSON.parse(data);
      }
    } catch (err) {
      return null;
    }

    return null;
  }

  static cleaup() {
    SessionStorage.storage.removeItem(SessionStorage.stateKey);
    SessionStorage.storage.removeItem(SessionStorage.securityContextKey);
  }
}
