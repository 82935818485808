import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import RestoreLandingPage from 'components/pages/RestoreLanding';
import { setToken, setStart } from 'redux/modules/Restore/actions';
import { updateField } from 'redux/modules/PartnerLogin/actions';

export default connect(
  () => ({}),
  (dispatch) =>
    bindActionCreators({ setStart, setToken, updateField }, dispatch)
)(RestoreLandingPage);
