import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { Loading } from '@utilitywarehouse/partner-ui-mui-components';
import { connector } from 'redux/utils/connector';
import browserHistory from 'components/root/browserHistory';
import {
  STORE_NAME,
  CONNECTION_STATE,
} from 'redux/modules/RemoteSession/v2/constants';
import { ActorType } from 'app/redux/modules/RemoteSession/v2/actor';
import { RemoteSessionApi } from 'redux/modules/RemoteSession/v2/api';
import { startPartnerSession } from 'redux/modules/RemoteSession/v2/actions';
import { ApplicationApi } from 'redux/modules/App/api';
import { PartnerLoginApi } from 'redux/modules/PartnerLogin/api';
import RemoteSupport from 'app/components/modules/Remote/PartnerForm/RemoteSupport';

const RemoteSupportContainer = ({
  isLoggedIn,
  partnerName,
  remoteDisplayName,
  partnerId,
  applicationId,
  token,
  appointmentCode,
  started,
  connection,
  error,
  actor,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (started && actor.type !== ActorType.PARTNER) {
      return browserHistory.push('/');
    }
    dispatch(
      startPartnerSession(
        { uuid: applicationId, token, partnerId },
        appointmentCode,
        remoteDisplayName || partnerName,
        partnerId,
        false
      )
    );
  }, []);

  if (!isLoggedIn || !applicationId) {
    return <Redirect to="/" />;
  }

  if (!started) {
    return (
      <Box py={10}>
        <Loading loadingText="Starting remote appointment" />
      </Box>
    );
  }
  return (
    <RemoteSupport
      accessCode={appointmentCode}
      connection={connection}
      error={error}
    />
  );
};

RemoteSupportContainer.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  partnerName: PropTypes.string,
  remoteDisplayName: PropTypes.string,
  partnerId: PropTypes.string,
  applicationId: PropTypes.string.isRequired,
  token: PropTypes.string,
  appointmentCode: PropTypes.string,
  started: PropTypes.bool,
  connection: PropTypes.oneOf(Object.values(CONNECTION_STATE)),
  error: PropTypes.any,
  actor: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = (state) => ({
  isLoggedIn: PartnerLoginApi.isPartnerLoggedIn(state),
  partnerName: PartnerLoginApi.getPartnerName(state),
  remoteDisplayName: PartnerLoginApi.getRemoteDisplayName(state),
  partnerId: PartnerLoginApi.getPartnerId(state),
  applicationId: ApplicationApi.getCurrentApplication(state),
  token: ApplicationApi.getToken(state),
  appointmentCode: ApplicationApi.getRemoteAppointmentCode(state),
  started: RemoteSessionApi.isStarted(state),
  connection: RemoteSessionApi.getLocalConnection(state),
  error: RemoteSessionApi.getError(state),
  actor: RemoteSessionApi.getActor(state),
});

export default connector(STORE_NAME, mapStateToProps)(RemoteSupportContainer);
