import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import RemoteCursor from 'components/modules/Remote/RemoteCursor';
import { RemoteSessionApi } from '../api';
import { ActorType } from '../actor';

const RemoteCursors = () => {
  const isStarted = useSelector(RemoteSessionApi.isStarted);
  const partnerName = useSelector((state) =>
    RemoteSessionApi.getNameFromRoster(state, ActorType.PARTNER)
  );
  const supportingName = useSelector((state) =>
    RemoteSessionApi.getNameFromRoster(state, ActorType.SUPPORTING_PARTNER)
  );
  if (!isStarted) return null;

  return (
    <Fragment>
      <RemoteCursor actorType={ActorType.CUSTOMER} />
      <RemoteCursor actorType={ActorType.PARTNER} name={partnerName} />
      <RemoteCursor
        actorType={ActorType.SUPPORTING_PARTNER}
        name={supportingName}
      />
    </Fragment>
  );
};

export default RemoteCursors;
