import Immutable from 'immutable';
import isEqual from 'lodash/isEqual';
import { STORE_NAME as RemoteSessionName } from 'redux/modules/RemoteSession/v2/constants';
import { filterPaths } from 'redux/modules/RemoteSession/v2/filter';

const areKeysEquals = (a, b) => {
  return isEqual(a.keys(), b.keys());
};

// https://github.com/immutable-js/immutable-js/issues/762#issuecomment-213566603
const mergeDeepOverwriteLists = (a, b) => {
  if (
    a &&
    a.mergeWith &&
    !Immutable.List.isList(a) &&
    !Immutable.List.isList(b) &&
    areKeysEquals(a, b)
  ) {
    return a.mergeWith(mergeDeepOverwriteLists, b);
  }

  return b;
};

export const mergeFiltered = (oldState, newState, filterPaths = []) => {
  for (const path of filterPaths) {
    const oldValue = oldState.getIn(path);
    if (!oldValue) {
      continue;
    }

    newState = newState.setIn(path, oldValue);
  }

  return oldState.mergeWith(mergeDeepOverwriteLists, newState);
};

export const merge = (oldState, newState) => {
  const actor = oldState.getIn([RemoteSessionName, 'actor']);
  const actorJS = actor ? actor.toJS() : {};
  const paths = filterPaths(actorJS);

  return mergeFiltered(oldState, newState, paths);
};
