import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { Loading } from '@utilitywarehouse/partner-ui-mui-components';

import { ApplicationApi } from 'redux/modules/App/api';
import { PartnerLoginApi } from 'redux/modules/PartnerLogin/api';
import { CONNECTION_STATE } from 'redux/modules/RemoteSession/v2/constants';
import { connector } from 'redux/utils/connector';
import NewOptions from 'components/modules/Remote/PartnerForm/NewOptions';

import { STORE_NAME } from '../constants';
import { startPartnerSession } from '../actions';
import { RemoteSessionApi } from '../api';

const PartnerFormContainer = ({
  isLoggedIn,
  partnerName,
  remoteDisplayName,
  partnerId,
  applicationId,
  token,
  appointmentCode,
  started,
  connection,
  error,
}) => {
  const dispatch = useDispatch();
  const isApplicationCreated = !!applicationId;

  useEffect(() => {
    dispatch(
      startPartnerSession(
        { uuid: applicationId, token, partnerId },
        appointmentCode,
        remoteDisplayName || partnerName,
        partnerId
      )
    );
  }, []);

  if (!isLoggedIn || !isApplicationCreated) {
    return <Redirect to="/" />;
  }

  return started ? (
    <NewOptions
      accessCode={appointmentCode}
      connection={connection}
      error={error}
    />
  ) : (
    <Box py={10}>
      <Loading loadingText="Starting remote appointment" />
    </Box>
  );
};

PartnerFormContainer.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  partnerName: PropTypes.string,
  remoteDisplayName: PropTypes.string,
  partnerId: PropTypes.string,
  applicationId: PropTypes.string.isRequired,
  token: PropTypes.string,
  appointmentCode: PropTypes.string,
  started: PropTypes.bool,
  connection: PropTypes.oneOf(Object.values(CONNECTION_STATE)),
  error: PropTypes.any,
};

const mapStateToProps = (state) => ({
  isLoggedIn: PartnerLoginApi.isPartnerLoggedIn(state),
  partnerName: PartnerLoginApi.getPartnerName(state),
  remoteDisplayName: PartnerLoginApi.getRemoteDisplayName(state),
  partnerId: PartnerLoginApi.getPartnerId(state),
  applicationId: ApplicationApi.getCurrentApplication(state),
  token: ApplicationApi.getToken(state),
  appointmentCode: ApplicationApi.getRemoteAppointmentCode(state),
  started: RemoteSessionApi.isStarted(state),
  connection: RemoteSessionApi.getLocalConnection(state),
  error: RemoteSessionApi.getError(state),
});

export default connector(STORE_NAME, mapStateToProps)(PartnerFormContainer);
