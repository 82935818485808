import { ThankYou, YourDetails } from 'redux/modules/Routes/constants';
import { GET_BOILER_COVER_QUOTE_REQUEST_SUCCESS } from 'redux/modules/BoilerCover/constants';
import { BILLPROT_SET_ACCEPT_DIALOG } from 'redux/modules/BillIncomeProtector/types';
import { ActorType } from './actor';

export const STORE_NAME = 'RemoteSession';

export const ACTION_TYPE = {
  START_SESSION: 'REMOTE_SESSION/START_SESSION',
  SESSION_UPDATE: 'REMOTE_SESSION/SESSION_UPDATE',
  END_SESSION: 'REMOTE_SESSION/END_SESSION',
  SET_PREVIOUS_CONTROL: 'REMOTE_SESSION/SET_PREVIOUS_CONTROL',
  SET_PREVIOUS_PAGE_SECURE: 'REMOTE_SESSION/SET_PREVIOUS_PAGE_SECURE',
  SECURE_PAGE: 'REMOTE_SESSION/SECURE_PAGE',

  SNACKBAR_CLOSE: 'REMOTE_SESSION/SNACKBAR_CLOSE',
  PASS_CONTROL: 'REMOTE_SESSION/PASS_CONTROL',
  TAKE_CONTROL: 'REMOTE_SESSION/TAKE_CONTROL',
  REJECT_CONNECTION: 'REMOTE_SESSION/REJECT_CONNECTION',
  ACCEPT_CONNECTION: 'REMOTE_SESSION/ACCEPT_CONNECTION',
  REQUEST_CONTROL: 'REMOTE_SESSION/REQUEST_CONTROL',
  REQUEST_CONTROL_ACCEPT: 'REMOTE_SESSION/REQUEST_CONTROL_ACCEPT',
  REQUEST_CONTROL_DISMISS: 'REMOTE_SESSION/REQUEST_CONTROL_DISMISS',

  STATE_UPDATE_RECEIVED: 'REMOTE_SESSION/STATE_UPDATE_RECEIVED',
};

export const CONNECTION_STATE = {
  CONNECTING: 'CONNECTING',
  DISCONNECTED: 'DISCONNECTED',
  CONNECTED: 'CONNECTED',
  ENDED: 'ENDED',
};

export const MAIN_CONTROL = 'MAIN';

export const SECURE_PATHNAMES = [
  { path: YourDetails, control: MAIN_CONTROL },
  { path: ThankYou, control: ActorType.PARTNER },
  { path: '/order/aboutyou', control: MAIN_CONTROL },
  { path: '/checkout/payment', control: MAIN_CONTROL },
  { path: '/checkout/verification', control: MAIN_CONTROL },
];

// for pages which can fire the secureAction
export const CONTAIN_SECURE_ACTION = [
  '/boilerhomecover',
  '/billincomeprotector',
];

export const SECURE_ACTIONS = [
  GET_BOILER_COVER_QUOTE_REQUEST_SUCCESS,
  BILLPROT_SET_ACCEPT_DIALOG,
];
