import Immutable from 'immutable';

import { ACTION_TYPE } from './constants';

const initialState = Immutable.fromJS({
  started: false,
  actor: undefined,
  connection: undefined,
  confirm: {
    phrase: undefined,
  },
  confirmConnectionDialog: {
    isOpen: false,
  },
  requestControlDialog: {
    isOpen: false,
    requestFrom: undefined,
  },
  infoSnackbar: {
    isOpen: false,
    message: '',
  },
  control: null,
  roster: {},
  config: {},
  appointmentCode: undefined,
  isCurrentPageSecure: false,
  showPresentation: false,
  handshakeComplete: false,
  previousControl: null,
  previousPageSecure: null,
});

export default function RemoteSessionReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE.START_SESSION:
      return initialState.mergeDeep({
        started: true,
        actor: action.actor,
        roster: {},
      });
    case ACTION_TYPE.SESSION_UPDATE:
      return state.mergeDeep(action.state);
    case ACTION_TYPE.SET_PREVIOUS_CONTROL:
      return state.set('previousControl', action.control);
    case ACTION_TYPE.SET_PREVIOUS_PAGE_SECURE:
      return state.set('previousPageSecure', action.secure);
    case ACTION_TYPE.END_SESSION:
      return initialState;
    default:
      return state;
  }
}
