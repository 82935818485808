import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationApi } from 'app/redux/modules/App/api';
import { PartnerLoginApi } from 'redux/modules/PartnerLogin/api';
import RemotePageUi from 'pages/Remote';

const RemotePageContainer = () => (
  <RemotePageUi
    isRemoteSession={useSelector(ApplicationApi.isRemoteSession)}
    isPartnerLoggedIn={useSelector(PartnerLoginApi.isPartnerLoggedIn)}
  />
);

export default RemotePageContainer;
