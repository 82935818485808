import { throttle } from 'lodash';
import { getRemoteCursorId } from 'app/components/modules/Remote/RemoteCursor';

export class Viewport {
  cleanups = [];

  constructor() {}

  onLocalScrollPositionUpdate(cb) {
    const onScroll = throttle(() => {
      const { scrollX, scrollY } = window;
      const { clientWidth, clientHeight } = document.body;
      cb({
        scrollX: scrollX / clientWidth,
        scrollY: scrollY / clientHeight,
      });
    }, 300);

    document.addEventListener('scroll', onScroll);

    this.cleanups.push(() => {
      document.removeEventListener('scroll', onScroll);
    });
  }

  onLocalCursorPositionUpdate(cb) {
    const onMouseMove = throttle((e) => {
      const { pageX, pageY } = e;
      cb({
        cursorX: pageX / document.body.clientWidth,
        cursorY: pageY / document.body.clientHeight,
      });
    }, 100);

    document.addEventListener('mousemove', onMouseMove);

    this.cleanups.push(() => {
      document.removeEventListener('mousemove', onMouseMove);
    });
  }

  updateScrollPosition(position) {
    const { scrollX, scrollY } = position;
    const { clientWidth, clientHeight } = document.body;

    try {
      window.scroll({
        left: scrollX * clientWidth,
        top: scrollY * clientHeight,
        behavior: 'smooth',
      });
    } catch (error) {
      // usupported browser
    }
  }

  updateCursorPosition({ from, cursorX, cursorY }) {
    let cursorEl = document.getElementById(getRemoteCursorId(from));

    if (!cursorEl) {
      return;
    }
    cursorEl.style.left = cursorX * document.body.clientWidth + 'px';
    cursorEl.style.top = cursorY * document.body.clientHeight + 'px';
  }

  cleanup() {
    while (this.cleanups.length > 0) {
      this.cleanups.shift()();
    }
  }
}
