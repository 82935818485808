import { connector } from 'redux/utils/connector';
import RemoteSessionOverlay from 'modules/Remote/RemoteSessionOverlay';

import { RemoteSessionApi } from '../api';
import { ApplicationApi } from 'redux/modules/App/api';
import { STORE_NAME } from '../constants';
import * as actions from '../actions';

export default connector(
  STORE_NAME,
  (state) => ({
    applicationId: ApplicationApi.getCurrentApplication(state),
    hasRemoteStarted: RemoteSessionApi.isStarted(state),
    hasSensitiveContent: RemoteSessionApi.isCurrentPageSecure(state),
    connectionFrom: RemoteSessionApi.getConnectionFrom(state),
    peers: RemoteSessionApi.getPeers(state),
    roster: RemoteSessionApi.getRoster(state),
    remoteSession: {
      local: RemoteSessionApi.getLocal(state),
      remote: RemoteSessionApi.getRemote(state),
      driver: RemoteSessionApi.getControlActor(state),
      error: RemoteSessionApi.getError(state),
      mainPeer: RemoteSessionApi.getMainPeer(state),
    },
    isInfoSnackbarOpen: RemoteSessionApi.isSnackbarOpen(state),
    infoSnackbarMessage: RemoteSessionApi.getSnackbarMessage(state),
    isConfirmConnectionDialogOpen: RemoteSessionApi.isConfirmConnectionDialogOpen(
      state
    ),
    isRequestControlDialogOpen: !!RemoteSessionApi.isRequestControlDialogOpen(
      state
    ),
    requestFromName: RemoteSessionApi.getRequestControlFromName(state),
  }),
  {
    onAcceptConnectionDialogAccept: actions.acceptConnection,
    onAcceptConnectionDialogReject: actions.rejectConnection,
    onAcceptRequestControl: actions.acceptRequestControl,
    onDismissRequestControl: actions.dismissRequestControl,
    onEndSessionDialogConfirm: actions.endSession,
    onInfoSnackbarClose: actions.snackbarClose,
  }
)(RemoteSessionOverlay);
