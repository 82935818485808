import { eventChannel } from 'redux-saga';
import {
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeLatest,
  all,
  cancel,
} from 'redux-saga/effects';
import browserHistory from 'components/root/browserHistory';
import { trackEvent } from 'app/redux/modules/Analytics/actions';
import analyticsSelectors from 'app/lib/analytics/AnalyticsProvider/selectors';
import { EVENTS } from 'app/lib/analytics/constants';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import { getLogger, level } from 'app/lib/logger';
import * as applicationActions from 'app/redux/modules/App/actions';
import {
  APP_PUT_REMOTE_APPLICATION_SUPPORTING_SUCCESS,
  STORE_NAME as AppStore,
} from 'app/redux/modules/App/constants';
import {
  PARTNER_LOGOUT,
  STORE_NAME as PartnerLogin,
} from 'app/redux/modules/PartnerLogin/constants';
import { APP_START_AGAIN } from 'app/redux/modules/App/constants';

import * as notificationActions from 'redux/modules/Notifications/actions';
import { notificationKeys } from 'redux/modules/Notifications/library';
import * as remoteSessionActions from './actions';
import { SECURE_ACTIONS, ACTION_TYPE, CONNECTION_STATE } from './constants';
import { Session } from './session';
import { Actor, ActorType } from './actor';
import { RemoteSessionApi } from './api';

const logLevel = level.info;
const loggerBase = getLogger('[remote-session]', logLevel, console);
const loggerSaga = loggerBase.child('[saga]', logLevel);
const loggerSession = loggerSaga.child('[session]', logLevel);

export function* combinedSagas() {
  yield takeLatest(ACTION_TYPE.START_SESSION, startSession);
  yield takeLatest(ACTION_TYPE.SESSION_UPDATE, updateSession);

  yield fork(recoverSession);
}

function sessionConfig(actor) {
  if (!actor.isPartner()) return {};

  return {};
}

// HACK: to access current session to execute forced remote controle takeover
// wich is necessary for the remote restore session to work since the partner
// has to be in control to apply the saved snapshot

let currentSession;
export const getCurrentSession = () => currentSession;

function* startSession({ application, appointmentCode, actor: actorJS }) {
  const actor = new Actor(actorJS);

  loggerSaga.info('startSession');
  const config = yield sessionConfig(actor);

  const session = new Session(
    appointmentCode,
    actor,
    application,
    loggerSession,
    config
  );

  currentSession = session;

  yield fork(runSession, session);
}

function* handleControlChange(state) {
  const {
    partnerId,
    supportingPartnerId,
    currentPartnerId,
    handshakeComplete,
  } = yield select((state) => ({
    currentPartnerId: state.getIn([PartnerLogin, 'profile', 'id'], null),
    partnerId: state.getIn([AppStore, 'remote', 'partnerId']),
    supportingPartnerId: state.getIn([
      AppStore,
      'remote',
      'supportingPartnerId',
    ]),
    applicationId: state.getIn([AppStore, 'application', 'id']),
    handshakeComplete: RemoteSessionApi.isHandshakeComplete(state),
  }));

  const { control } = state;

  let currentDriver;

  switch (control) {
    case ActorType.PARTNER:
      currentDriver = partnerId;
      break;
    case ActorType.SUPPORTING_PARTNER:
      currentDriver = supportingPartnerId;
      break;
    case ActorType.CUSTOMER:
      currentDriver = null;
      break;
    default:
      currentDriver = null;
  }

  const isDriving = currentDriver === currentPartnerId;
  const controlMessageMap = {
    [ActorType.PARTNER]: notificationKeys.REMOTE_CONTROL_SET_PARTNER,
    [ActorType.SUPPORTING_PARTNER]:
      notificationKeys.REMOTE_CONTROL_SET_SUPPORTING,
    [ActorType.CUSTOMER]: notificationKeys.REMOTE_CONTROL_SET_CUSTOMER,
  };

  const previousControl = yield select(RemoteSessionApi.getPreviousControl);
  if (handshakeComplete) {
    if (control !== previousControl) {
      let message;
      if (isDriving) {
        message = notificationKeys.REMOTE_CONTROL_SET_SELF;
      } else {
        message = controlMessageMap[control];
      }

      const notification = {
        message,
      };
      yield put(notificationActions.addNotification(notification));

      const controlChangeState = yield select(
        analyticsSelectors[EVENTS.REMOTE_CONTROL_CHANGE]
      );
      yield put(trackEvent(EVENTS.REMOTE_CONTROL_CHANGE, controlChangeState));
    }
  }
}

function* updateSession({ state }) {
  yield fork(handleControlChange, state);

  const pageIsSecure = yield select(RemoteSessionApi.isCurrentPageSecure);
  const previousPageSecure = yield select(
    RemoteSessionApi.getPreviousPageSecure
  );

  if (pageIsSecure) {
    yield put(
      notificationActions.setPersistentNotification({
        message: notificationKeys.REMOTE_PRIVATE,
        variant: 'error',
      })
    );
  } else if (!pageIsSecure && previousPageSecure) {
    yield put(notificationActions.setPersistentNotification(null));
  }
  yield put(remoteSessionActions.setPreviousControl(state.control));
  yield put(remoteSessionActions.setPreviousPageSecure(pageIsSecure));
}

function* recoverSession() {
  loggerSaga.info('recoverSession');

  const session = Session.recover(loggerSession);

  if (!session) {
    loggerSaga.info('recoverSession no session');
    return;
  }

  loggerSaga.info('recoverSession found');
  yield fork(runSession, session);
}

function* runSession(session) {
  let tasks = [];

  try {
    loggerSaga.info('runSession start');
    tasks = yield all([
      fork(handleHandshakeComplete, session),
      fork(handleConnectionStateChange, session),
      fork(connectSessionHandshakeComplete, session),
      fork(connectSessionPeerHandshakeComplete, session),
      fork(connectSessionSupportedApplication, session),
      fork(listenOnLocalStoreUpdate, session),
      fork(listenOnRemoteStoreUpdate, session),
      fork(listenOnSecureActionDispatch, session),
      fork(connectSessionController, session),
      fork(connectSessionEnd, session),
    ]);

    // either wait for the 'runloop' to finish, or for request to end
    yield race([
      session.run(),
      take(ACTION_TYPE.END_SESSION),
      take(PARTNER_LOGOUT),
      take(APP_START_AGAIN),
    ]);
  } catch (err) {
    Sentry.log(err, ERRORS.REMOTE_SESSION_RUN);
    loggerSaga.error('runSession error', err);
    yield put(applicationActions.logoutUserApplication());
    yield put(
      remoteSessionActions.remoteSessionError(
        'Sorry, an error occurred in your remote session'
      )
    );
    browserHistory.push('/');
  } finally {
    loggerSaga.info('runSession finished');
    session.cleanup();
    yield cancel(tasks);
  }
}

function* acceptConnection(session) {
  if (!session.controller.actor.isRemote) {
    yield put(remoteSessionActions.takeControl());
  }
  yield session.controller.confirmConnection();
}

function* connectSessionController(session) {
  // bridge UI action to controller
  yield takeLatest(ACTION_TYPE.PASS_CONTROL, () =>
    session.controller.passControl()
  );
  yield takeLatest(ACTION_TYPE.TAKE_CONTROL, () =>
    session.controller.takeControl()
  );
  yield takeLatest(ACTION_TYPE.REQUEST_CONTROL, () =>
    session.controller.requestControl()
  );
  yield takeLatest(ACTION_TYPE.REQUEST_CONTROL_ACCEPT, () =>
    session.controller.requestControlAccept()
  );
  yield takeLatest(ACTION_TYPE.REQUEST_CONTROL_DISMISS, () =>
    session.controller.requestControlDismiss()
  );
  yield takeLatest(
    ACTION_TYPE.ACCEPT_CONNECTION,
    acceptConnection.bind(null, session)
  );
  yield takeLatest(ACTION_TYPE.REJECT_CONNECTION, () =>
    session.controller.rejectConnection()
  );
  yield takeLatest(ACTION_TYPE.SNACKBAR_CLOSE, () =>
    session.controller.infoSnackbarClose()
  );
  yield takeLatest(ACTION_TYPE.END_SESSION, () =>
    session.controller.endSession()
  );
  yield takeLatest(
    APP_PUT_REMOTE_APPLICATION_SUPPORTING_SUCCESS,
    ({ supportingPartnerId }) =>
      session.controller.supportAccepted(supportingPartnerId)
  );
  yield takeLatest(SECURE_ACTIONS, (actionPayload) =>
    session.controller.secureAction(actionPayload)
  );
  yield takeLatest(ACTION_TYPE.SECURE_PAGE, (url) =>
    session.controller.securePage(url)
  );

  // bridge controller state updates to UI - redux store
  const channel = eventChannel((emit) => {
    session.controller.onUpdate((update = {}) => {
      emit(update);
    });

    return () => {};
  });

  while (true) {
    const update = yield take(channel);
    yield put(remoteSessionActions.sessionUpdate(update));
  }
}

function* handleHandshakeComplete(session) {
  const localActor = yield select(RemoteSessionApi.getActor);
  const onPeerHandshakeComplete = eventChannel((emit) => {
    session.controller.onPeerHandshakeComplete(({ actor }) => {
      emit(actor.type);
    });

    return () => {};
  });

  const onHandshakeComplete = eventChannel((emit) => {
    session.controller.onHandshakeComplete(() => {
      emit(true);
    });

    return () => {};
  });

  if (localActor.type === ActorType.SUPPORTING_PARTNER) {
    yield take(onHandshakeComplete);
    yield put(
      notificationActions.addNotification({
        message: notificationKeys.REMOTE_CLIENT_CONNECTED,
      })
    );
    yield put(notificationActions.setPersistentNotification(null));
  }
  while (true) {
    const actor = yield take(onPeerHandshakeComplete);
    switch (actor) {
      case ActorType.CUSTOMER:
        yield put(
          notificationActions.addNotification({
            message: notificationKeys.REMOTE_CLIENT_CUSTOMER_CONNECTED,
          })
        );
        break;
      case ActorType.SUPPORTING_PARTNER:
        yield put(
          notificationActions.addNotification({
            message: notificationKeys.REMOTE_CLIENT_SUPPORTING_CONNECTED,
          })
        );
        break;
    }
    yield put(notificationActions.setPersistentNotification(null));
  }
}

function* handleConnectionStateChange(session) {
  const channel = eventChannel((emit) => {
    session.controller.onConnectionStateChange((connectionState) => {
      emit(connectionState);
    });

    return () => {};
  });

  while (true) {
    const update = yield take(channel);
    const handshakeComplete = yield select(
      RemoteSessionApi.isHandshakeComplete
    );

    // logic...
    switch (update) {
      case CONNECTION_STATE.CONNECTING: {
        if (!handshakeComplete) {
          yield put(
            notificationActions.setPersistentNotification({
              message: notificationKeys.REMOTE_CLIENT_CONNECTING,
            })
          );
        }
        break;
      }
      case CONNECTION_STATE.DISCONNECTED:
        yield put(
          notificationActions.addNotification({
            message: notificationKeys.REMOTE_CLIENT_DISCONNECTED,
            variant: 'error',
          })
        );
        break;
      case CONNECTION_STATE.ENDED:
        yield put(
          notificationActions.addNotification({
            message: notificationKeys.REMOTE_CLIENT_ENDED,
            variant: 'error',
          })
        );
        break;
      default:
        break;
    }
  }
}

function* connectSessionHandshakeComplete(session) {
  const channel = eventChannel((emit) => {
    session.controller.onHandshakeComplete((update = {}) => {
      emit(update);
    });

    return () => {};
  });
  const application = yield take(channel);

  // on handshake complete, different JTC behaviours are required for each actor
  switch (true) {
    case session.actor.isSupportingPartner():
    case session.actor.isPartner() && !session.actor.isRemote:
    case session.actor.isCustomer(): {
      yield put(
        remoteSessionActions.remoteApplicationReceived(
          application.token,
          application.uuid,
          session.appointmentCode,
          application.partnerId
        )
      );

      browserHistory.push('/presentation');
    }
  }
}

function* connectSessionSupportedApplication(session) {
  const channel = eventChannel((emit) => {
    session.controller.onSupportedApplication((update = {}) => {
      emit(update);
    });

    return () => {};
  });

  while (true) {
    const { supportingPartnerId } = yield take(channel);
    const isLoading = !!(yield select()).getIn(['App', 'remote', 'loading']);
    if (isLoading) {
      yield delay(1000);
    }

    // In case the supporting peer retries but the api has already been updated,
    // just re-send the supported_accepted signal
    const isSupported = !!(yield select()).getIn([
      'App',
      'remote',
      'supportingPartnerId',
    ]);

    if (isSupported) {
      session.controller.supportAccepted(supportingPartnerId);
      continue;
    }

    yield put(
      remoteSessionActions.remoteApplicationSupportingUpdate(
        supportingPartnerId
      )
    );
  }
}

function* connectSessionPeerHandshakeComplete(session) {
  const channel = eventChannel((emit) => {
    session.controller.onPeerHandshakeComplete((update = {}) => {
      emit(update);
    });

    return () => {};
  });

  while (true) {
    const { actor } = yield take(channel);
    const peer = new Actor(actor);

    if (session.actor.isPartner() && peer.isSupportingPartner()) {
      yield put(remoteSessionActions.setSupportingPartner(peer.id));
    }

    // send a warm welcome with a full state dump for the new peers
    if (session.controller.control === session.actor.type) {
      const state = yield select();
      session.store.localUpdate({
        newState: state,
        newPath: browserHistory.location.pathname,
        replace: true,
      });
    }
  }
}

function* connectSessionEnd(session) {
  const channel = eventChannel((emit) => {
    session.controller.onEndSession(emit);
    return () => {};
  });

  yield take(channel);
  yield put(applicationActions.logoutUserApplication());
}

function* listenOnLocalStoreUpdate(session) {
  let diffsSend = 15;

  while (true) {
    const hasApplication = (yield select()).getIn(['App', 'application', 'id']);
    if (!hasApplication) {
      yield delay(1000);
      continue;
    }

    const oldState = yield select();
    const oldPath = browserHistory.location.pathname;
    yield delay(1000);
    const newState = yield select();
    const newPath = browserHistory.location.pathname;

    if (diffsSend === 15) {
      session.store.localUpdate({
        newState,
        newPath,
        replace: true,
      });
      diffsSend = 0;
    } else {
      session.store.localUpdate({
        oldState,
        newState,
        oldPath,
        newPath,
      });
      diffsSend++;
    }

    // As the routeChange can will trigger changes on v2/mux, blocking
    // localUpdates to be sent across, ensure 200ms delay before sending it
    if (oldPath !== newPath) {
      yield delay(200);
      session.controller.routeChange({ oldPath, newPath });
    }
  }
}

function* listenOnRemoteStoreUpdate(session) {
  const channel = eventChannel((emit) => {
    session.store.onRemoteUpdate((update) => {
      emit(update);
    });

    return () => {};
  });

  while (true) {
    const { state, diff, path } = yield take(channel);

    yield put(remoteSessionActions.stateUpdateReceived(state, diff));

    if (path && path !== browserHistory.location.pathname) {
      session.controller.checkPageSecureByUserAction(path);
      browserHistory.push(path);
    }
  }
}

function* listenOnSecureActionDispatch(session) {
  const channel = eventChannel((emit) => {
    session.controller.onSecureActionDispatch((update = {}) => {
      emit(update);
    });

    return () => {};
  });

  while (true) {
    // secure action from a peer has to be dispatched
    // for the state to be synced
    const actionPayload = yield take(channel);
    yield put(actionPayload);
  }
}
