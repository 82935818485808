import Immutable from 'immutable';

import * as types from './types';

export const initialState = Immutable.fromJS({
  loading: false,
  error: null,
  token: null,
  start: null,
  store: null,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.APP_STATE_RESTORE:
      return state.merge({ loading: true, error: null });
    case types.APP_STATE_RESTORE_SUCCESS:
      return state.merge({ loading: false, error: null });
    case types.APP_STATE_RESTORE_FAILURE:
      return state.merge({ loading: false, error: action.error });
    case types.APP_RESTORE_TOKEN_SET:
      return state.set('token', action.token);
    case types.APP_RESTORE_START_SET:
      return state.set('start', action.start);
    case types.APP_RESTORE_STORE_SET:
      return state.set('store', action.store);
    default:
      return state;
  }
}
