import { connect as socketClient } from 'socket.io-client';
import { REMOTE_SESSION_SIGNALLING_ENDPOINT } from 'config';
import { Viewport } from './viewport';
import { Signalling } from './signalling';
import { Mux } from './mux';
import { Store } from './store';
import { DataChannel } from './dataChannel';
import { Controller } from './controller';
import ReduxMiddleware from 'app/redux/modules/RemoteSession/v2/middleware';
import { Actor } from 'app/redux/modules/RemoteSession/v2/actor';
import { SessionStorage } from 'app/redux/modules/RemoteSession/v2/sessionStorage';

export class Session {
  static recover(logger) {
    const data = SessionStorage.loadState();
    const security = SessionStorage.loadSecurityContext();

    if (!data) {
      return null;
    }

    const session = new Session(
      data.appointmentCode,
      new Actor(data.actor),
      data.application,
      logger
    );

    session.controller.injectState(data.state);

    if (!security) {
      return session;
    }

    session.controller.securityContext.injectState(security);

    return session;
  }

  constructor(
    appointmentCode,
    actor, // {type, displayName}
    application,
    logger,
    config,
    signalling = new Signalling(logger.child('[signalling]', 'info'))
  ) {
    this.logger = logger;

    const viewport = (this.viewport = new Viewport());
    const mux = (this.mux = Mux.forActor(actor));
    const store = (this.store = new Store());
    const dataChannel = (this.dataChannel = new DataChannel(actor));
    this.signalling = signalling;

    this.controller = new Controller(
      signalling,
      appointmentCode,
      actor,
      application,
      logger.child('[controller]', 'debug'),
      config
    );

    this.appointmentCode = appointmentCode;
    this.actor = actor;

    viewport.onLocalCursorPositionUpdate((u) => mux.localCursorPosition(u));
    mux.onLocalCursorPositionUpdate((u) =>
      dataChannel.sendCursorPositionUpdate(u)
    );
    dataChannel.onCursorPositionUpdate((u) =>
      mux.remoteCursorPositionUpdate(u)
    );
    mux.onRemoteCursorPositionUpdate((u) => viewport.updateCursorPosition(u));

    viewport.onLocalScrollPositionUpdate((u) => mux.localScrollPosition(u));
    mux.onLocalScrollPositionUpdate((u) =>
      dataChannel.sendScrollPositionUpdate(u)
    );
    dataChannel.onScrollPositionUpdate((u) =>
      mux.remoteScrollPositionUpdate(u)
    );
    mux.onRemoteScrollPositionUpdate((u) => viewport.updateScrollPosition(u));

    store.onLocalUpdate((u) => mux.localStoreUpdate(u));
    mux.onLocalStoreUpdate((u) => dataChannel.sendStoreUpdate(u));
    dataChannel.onStoreUpdate((u) => mux.remoteStoreUpdate(u));
    mux.onRemoteStoreUpdate((u) => store.updateStore(u));

    // make sure we've set mux in the right mode everytime it changes
    ReduxMiddleware.setActor(this.actor.type);

    this.controller.onControlChange((control) => {
      this.mux.configure({ control });
      ReduxMiddleware.setControl(control);
    });

    this.controller.disconnected();
  }

  run() {
    this.controller.enablePersistence();
    this.controller.enableInternalStoreUpdates();

    const location = new URL(REMOTE_SESSION_SIGNALLING_ENDPOINT);
    const endpoint = location.origin + location.pathname;
    const socketConfig = {};
    location.searchParams.forEach((value, key) => {
      switch (value) {
        case 'true':
          value = true;
          break;
        case 'false':
          value = false;
          break;
        case 'null':
          value = null;
          break;
      }
      socketConfig[key] = value;
    });

    socketConfig.autoConnect = false;

    if (!socketConfig.query) {
      socketConfig.query = {};
    }

    socketConfig.query['actor-type'] = this.actor.type;
    socketConfig.query['actor-name'] = this.actor.name;

    ReduxMiddleware.enable();

    return new Promise((resolve) => {
      // this will only resolve on receiving a EMD singal from the other side

      this.logger.info('connecting: ', endpoint);

      const envelope = (routingKey, m) => {
        return {
          from: this.actor.type,
          ...m,
          __routing: routingKey,
        };
      };

      const socket = (this.socket = socketClient(endpoint, socketConfig));

      // pass signals onto signal handler
      socket.on('signal', (m) => {
        // prevent acting on signals sent from the same actor type
        // e.g. stop a partner sending a CUSTOMER signal to the CUSTOMER
        if (m.from === this.controller.actor.type) {
          return;
        }
        this._signalLog('received signal', m);
        this.signalling.handle(m);
      });

      // pass data onto data channel only if handshake has been completed
      socket.on('data', (m) => {
        if (this.controller.appointmentCode === m.__routing) return;
        if (!this.controller.securityContext.completed()) return;
        this.dataChannel.handleMessage(m);
      });

      let secretChannelId = this.controller.securityContext.sharedSecret();

      // on each new signal from signalling send to socket server
      this.signalling.onSignal((m) => {
        if (m.secure) {
          if (!secretChannelId) {
            return this.logger.info(
              'failed to send secure signal, no secretChannelId'
            );
          }
          this.logger.debug(
            'sending secure signal',
            envelope(secretChannelId, m)
          );
          socket.emit('signal', envelope(secretChannelId, m));
          return;
        }
        this._signalLog('sending signal', envelope(this.appointmentCode, m));
        socket.emit('signal', envelope(this.appointmentCode, m));
      });

      this.controller.onEndSession(({ from }) => {
        const peer = new Actor({ type: from });
        if (
          !peer.isSupportingPartner() ||
          (peer.isSupportingPartner() && this.actor.isSupportingPartner())
        ) {
          return resolve();
        }
      });

      let dataChannelConnected = false;
      const joinDataChannel = (secret) => {
        secretChannelId = secret;
        this.logger.debug('joined: ', secret);
        socket.emit('/join', secret);
        if (dataChannelConnected) {
          return;
        }
        this.dataChannel.onData((m) => {
          this.logger.debug(
            'sending data on ',
            envelope(secret, { type: m.type })
          );
          socket.emit('data', envelope(secret, m));
        });
        dataChannelConnected = true;
      };

      // on end of handshake connect data channel via secure channel ID
      this.controller.onSecretAgreed(({ secret }) => {
        joinDataChannel(secret);
      });

      let reconnectionTimeout;
      const reconnect = () => {
        clearTimeout(reconnectionTimeout);
        reconnectionTimeout = setTimeout(() => {
          socket.open();
        }, 2000);
      };

      socket.on('reconnecting', () => {
        this.logger.info('reconnecting');
        this.controller.connecting();
      });

      const handleError = (type) => (err) => {
        this.logger.error(`error(${type}): `, err);
        this.controller.disconnected();
        //reject(err);
        reconnect();
      };

      const handleDisconnect = (reason) => {
        this.logger.info(`disconnected: `, reason);
        this.controller.disconnected(reason);

        if (reason === 'io client disconnect') {
          return resolve();
        }
        reconnect();
      };

      socket.on('error', handleError('error'));
      socket.on('connect_error', handleError('connect_error'));
      socket.on('connect_timeout', handleError('connect_timeout'));
      socket.on('reconnect_error', handleError('reconnect_error'));
      socket.on('reconnect_failed', handleError('reconnect_failed'));
      socket.on('disconnect', handleDisconnect);

      socket.on('connect', () => {
        this.logger.info('connected');

        // join room for signalling
        socket.emit('/join', this.appointmentCode);
        this.logger.debug('joined: ', this.appointmentCode);

        // join secret room if available (after reconnect)
        if (secretChannelId) {
          joinDataChannel(secretChannelId);
        }

        this.controller.connected();
      });

      // init connection
      this.controller.connecting();
      socket.open();
    });
  }

  cleanup() {
    this.controller.cleanup();
    if (this.socket) {
      this.socket.close();
    }
    this.viewport.cleanup();
    ReduxMiddleware.disable();
    SessionStorage.cleaup();
  }

  _signalLog(msg, payload) {
    if (payload.type === 'PRESENCE') {
      return;
    }

    this.logger.info(
      msg,
      JSON.parse(
        JSON.stringify({
          ...payload,
          application: payload.application
            ? {
                ...payload.application,
                token: undefined,
              }
            : undefined,
          secret: undefined,
          key: undefined,
        })
      )
    );
  }
}
