import EventEmitter from 'eventemitter3';
import { ActorType } from 'app/redux/modules/RemoteSession/v2/actor';
import { filter } from './filter';
import { differ } from './differ';

export class Mux {
  constructor(actor) {
    this.actor = actor;
    this.emitter = new EventEmitter();
    this.control = ActorType.CUSTOMER;
  }

  configure({ control }) {
    this.control = control;
  }

  drives() {
    return this.actor && this.control && this.actor.type == this.control;
  }

  watches() {
    return this.actor && this.control && this.actor.type != this.control;
  }

  localScrollPosition(update) {
    if (this.drives()) {
      this.emitter.emit('lspu', update);
    }
  }

  onLocalScrollPositionUpdate(cb) {
    this.emitter.on('lspu', cb);
  }

  remoteScrollPositionUpdate(update) {
    if (this.watches()) {
      this.emitter.emit('rspu', update);
    }
  }

  onRemoteScrollPositionUpdate(cb) {
    this.emitter.on('rspu', cb);
  }

  localCursorPosition(update) {
    this.emitter.emit('lcu', {
      ...update,
      from: this.actor.type,
    });
  }

  onLocalCursorPositionUpdate(cb) {
    this.emitter.on('lcu', cb);
  }

  remoteCursorPositionUpdate(update) {
    this.emitter.emit('rcu', update);
  }

  onRemoteCursorPositionUpdate(cb) {
    this.emitter.on('rcu', cb);
  }

  localStoreUpdate({ newState, oldState, newPath, oldPath, replace }) {
    if (!this.drives()) return;
    if (replace) {
      return this.emitter.emit('lsu', {
        state: filter(newState),
        path: newPath,
      });
    }

    const newStateFiltered = filter(newState);
    const oldStateFiltered = filter(oldState);
    const diff = differ(oldStateFiltered, newStateFiltered);
    if (diff.size || newPath !== oldPath) {
      this.emitter.emit('lsu', {
        diff,
        path: newPath,
      });
    }
  }

  onLocalStoreUpdate(cb) {
    this.emitter.on('lsu', cb);
  }

  remoteStoreUpdate(update) {
    if (this.watches()) {
      this.emitter.emit('rsu', update);
    }
  }

  onRemoteStoreUpdate(cb) {
    this.emitter.on('rsu', cb);
  }

  static forActor(actor) {
    switch (actor.type) {
      default:
        return new Mux(actor);
    }
  }
}
