import Immutable from 'immutable';
import { EventEmitter } from 'eventemitter3';

import browserHistory from 'components/root/browserHistory';
import { parsePathname } from 'redux/utils/parsePathname';
import { ActorType } from 'redux/modules/RemoteSession/v2/actor';
import SecurityContext from 'redux/modules/RemoteSession/v2/securityContext';
import { SessionStorage } from 'redux/modules/RemoteSession/v2/sessionStorage';

import Handshake from './handlers/handshake';
import { Signals, SignallingActorListeners } from './signalling';
import { isSecuredPath, containSecureAction, getMainPeer } from './util';
import {
  CONNECTION_STATE,
  MAIN_CONTROL,
  SECURE_ACTIONS,
  SECURE_PATHNAMES,
} from './constants';

const PRESENCE_INTERVAL = 5000;
const ROSTER_CLEAR_TIMEOUT = 10000;
const SYNC_INTERVAL = 500;

// Controller understands which bits of signalling should cause
// UI changes, and can also translate UI actions into relevant
// signals
export class Controller {
  constructor(signalling, appointmentCode, actor, application, logger, config) {
    this.persistenceEnabled = false;
    this.internalUpdatesEnabled = true;
    this.logger = logger;
    this.signalling = signalling;
    this.emitter = new EventEmitter();
    this.application = application;
    this.appointmentCode = appointmentCode;
    // state for the UI, controler shouldn't use it
    this.state = Immutable.fromJS({
      actor: actor.toJS(),
      roster: {},
      confirmConnectionDialog: {},
      requestControlDialog: {},
      infoSnackbar: {},
      control: ActorType.CUSTOMER,
      mainPeer: ActorType.CUSTOMER,
      isCurrentPageSecure: false,
      isCurrentPageSecureByUserAction: false,
      config,
    });
    this.config = config;
    this.actor = actor;
    // mainPeer indicates which peer is the trusted one
    this.mainPeer = ActorType.CUSTOMER;
    this.roster = {};
    this.control = ActorType.CUSTOMER;
    this.requestControlFrom = undefined;
    this.synced = false;

    this._handleRoster();

    this.signalling.on(Signals.CONTROL_CHANGE, ({ control }) => {
      this.emitter.emit('control-change', control);
    });

    this.signalling.on(Signals.REQUEST_CONTROL, ({ control }) => {
      this.emitter.emit('request-control', control);
    });

    this.signalling.on(Signals.SESSION_ENDED, ({ from }) => {
      this.emitter.emit('cleanup');
      this.emitter.emit('end-session', { from });
    });

    this.signalling.on(Signals.HANDSHAKE_COMPLETED, ({ actor, to }) => {
      this.emitter.emit('peer-handshake-complete', { actor, to });
    });

    this.signalling.on(
      Signals.APPLICATION_SUPPORTED_ACCEPTED,
      ({ supportingPartnerId }) => {
        this.emitter.emit('supported-application-accepted', {
          supportingPartnerId,
        });
      }
    );

    this.signalling.on(Signals.SECURE_ACTION, (action) => {
      this.emitter.emit('secure-action', action);
    });

    this.signalling.on(Signals.SECURE_PAGE, (data) => {
      this.emitter.emit('secure-page', data);
    });

    this.onControlChange((control) => this._handleOnControlChange(control));

    this.onRequestControl((control) => {
      this.requestControlFrom = control;
      this._update({
        requestControlDialog: { requestFrom: control },
      });
      this._handleRequestControlDialogOpen();
    });

    this.onSecureAction(({ actionPayload }) => {
      this._handleOnSecureAction(actionPayload);
    });

    this.onSecurePage(({ url }) => {
      this._handleOnSecurePage(url);
    });

    this.onRequestControlAccept(() => {
      this._handleOnRequestControlAccept();
    });

    this.onRequestControlDismiss(() => {
      this.requestControlFrom = undefined;
      this._handleRequestControlDialogClose();
    });

    this.onSharedConfig((config) => {
      this._update({ config });
      this.config = config;
    });

    this.onHandshakeComplete(() => this._handleOnHandshakeComplete());
    this.onHandshakeRejection(() => this._handleOnHandshakeRejection());
    this.onInfoSnackbarClose(() => this._handleInfoSnackbarClose());

    this.onRouteChange(({ newPath }) => {
      this._handleSecurePath(newPath);
    });

    this.onConfirmDialog((params) => this._handleOnConfirmDialog(params));

    this.onSendSyncConfig(() => this._sendSyncConfig());

    this.securityContext = SecurityContext.forActor(actor);
  }

  injectState(state) {
    this.state = Immutable.fromJS(state);
    if (state.control) {
      this._changeControl(state.control);
    }

    if (state.requestControlFrom) {
      this.requestControlFrom = state.requestControlFrom;
      this.requestControl();
    }

    if (state.config) {
      this.emitter.emit('shared-config', state.config);
    }
  }

  enablePersistence() {
    this.persistenceEnabled = true;
  }

  disablePersistence() {
    this.persistenceEnabled = false;
  }

  enableInternalStoreUpdates() {
    this.internalUpdatesEnabled = true;
  }

  disableInternalStoreUpdates() {
    this.internalUpdatesEnabled = false;
  }

  routeChange(params) {
    this.emitter.emit('route-change', params);
  }

  onRouteChange(cb) {
    this.emitter.on('route-change', cb);
  }

  onCleanup(cb) {
    this.emitter.on('cleanup', cb);
  }

  checkPageSecureByUserAction(path) {
    const isSecureActionPath = containSecureAction(path);
    if (!isSecureActionPath) {
      this._update({ isCurrentPageSecureByUserAction: false });
    }
  }

  _setMainPeer() {
    const newMainPeer = getMainPeer(this.actor, this.roster);

    if (this.mainPeer !== newMainPeer) {
      this.mainPeer = newMainPeer;
      this._update({ mainPeer: newMainPeer });
    }
  }

  _handleSecurePath(newPath) {
    this.checkPageSecureByUserAction(newPath);

    const isCurrentPageSecure = isSecuredPath(newPath);
    this._update({ isCurrentPageSecure });

    if (isCurrentPageSecure) {
      const control =
        SECURE_PATHNAMES.find(({ path }) => path === newPath)?.control ??
        MAIN_CONTROL;

      this._changeControl(control === MAIN_CONTROL ? this.mainPeer : control);
    }
  }

  _handleRoster() {
    const rosterTimeouts = {};
    const rosterCountdown = (key) => {
      clearTimeout(rosterTimeouts[key]);
      rosterTimeouts[key] = setTimeout(() => {
        this._update({
          roster: { [key]: { status: CONNECTION_STATE.DISCONNECTED } },
        });
      }, ROSTER_CLEAR_TIMEOUT);
    };

    // connect presence signal
    this.signalling.on(Signals.PRESENCE, ({ actor, presence }) => {
      rosterCountdown(actor.type);
      const rosterData = {
        status: presence,
        name: actor.name,
        isRemote: actor.isRemote,
      };

      // for internal use only (in controller)
      this.roster[actor.type] = rosterData;
      // UI state update
      this._update({
        roster: { [actor.type]: rosterData },
      });
      // once the peer is connected we select the main peer
      this._setMainPeer();
    });

    this.signalling.on(Signals.SESSION_ENDED, ({ from }) => {
      clearTimeout(rosterTimeouts[from]);
      if (this.roster[from]) this.roster[from].status = CONNECTION_STATE.ENDED;
      this._update({
        roster: { [from]: { status: CONNECTION_STATE.ENDED } },
      });
    });
  }

  onSecretAgreed(cb) {
    this.emitter.on('secret-agreed', cb);
  }

  onControlChange(cb) {
    this.emitter.on('control-change', cb);
  }

  onRequestControl(cb) {
    this.emitter.on('request-control', cb);
  }

  onRequestControlAccept(cb) {
    this.emitter.on('request-control-accept', cb);
  }

  onRequestControlDismiss(cb) {
    this.emitter.on('request-control-dismiss', cb);
  }

  onSecureAction(cb) {
    this.emitter.on('secure-action', cb);
  }

  onSecureActionDispatch(cb) {
    this.emitter.on('secure-action-dispatch', cb);
  }

  onSecurePage(cb) {
    this.emitter.on('secure-page', cb);
  }

  onUpdate(cb) {
    this.emitter.on('update', cb);
  }

  onConnectionStateChange(cb) {
    this.emitter.on('connection-state-change', cb);
  }

  onHandshakeComplete(cb) {
    this.emitter.on('handshake-complete', cb);
  }

  onHandshakeRejection(cb) {
    this.emitter.on('handshake-rejection', cb);
  }

  onInfoSnackbarClose(cb) {
    this.emitter.on('snackbar-close', cb);
  }

  onPeerHandshakeComplete(cb) {
    this.emitter.on('peer-handshake-complete', cb);
  }

  _persist() {
    if (!this.persistenceEnabled) {
      return;
    }

    const dataToSave = {
      state: this.state.toJS(),
      actor: this.actor.toJS(),
      // TODO: customer doesn't have the application here -- check if it can be an issue
      application: this.application,
      appointmentCode: this.appointmentCode,
    };

    SessionStorage.saveState(dataToSave);
  }

  _update(state) {
    const newState = this.state.mergeDeep(state);
    if (this.internalUpdatesEnabled && !newState.equals(this.state)) {
      this.state = newState;
      this.emitter.emit('update', newState.toJS());
      this._persist();
    }
  }

  _handleOnRequestControlAccept() {
    const requestFrom = this.requestControlFrom;
    if (!requestFrom) return;

    this._handleRequestControlDialogClose();
    setTimeout(() => {
      // this needs to happen on the next tick after the state is updated
      // otherwise it may lead to the race condition where the control
      // is changed but the state update blocked (and modal stays open)
      this._changeControl(requestFrom);
      this.requestControlFrom = undefined;
    }, 200);
  }

  _handleOnControlChange(control) {
    this._update({ control });
    this.control = control;
  }

  _handleOnSecureAction(actionPayload) {
    // only mainPeer can receive the secure action
    if (this.control !== this.mainPeer && this.actor.type === this.mainPeer) {
      // check if action is allowed
      if (SECURE_ACTIONS.includes(actionPayload.type)) {
        this.takeControl();
        this._update({ isCurrentPageSecureByUserAction: true });
        this.signalling.signalSecurePage({
          url: browserHistory.location.pathname,
        });
        this.emitter.emit('secure-action-dispatch', actionPayload);
      }
    }
  }

  _handleOnSecurePage(url) {
    if (this.actor.type === this.mainPeer && this.control !== this.mainPeer) {
      this.takeControl();
    }

    if (url && browserHistory.location.pathname !== url) {
      browserHistory.push(parsePathname(url));
    }
    this._update({ isCurrentPageSecureByUserAction: true });
  }

  connected() {
    this._update({ connection: CONNECTION_STATE.CONNECTED });
    this.emitter.emit('connection-state-change', CONNECTION_STATE.CONNECTED);

    this.presenceInterval = setInterval(() => {
      if (!this.securityContext.completed()) return;
      this.signalling.signalPresence(this.actor);
    }, PRESENCE_INTERVAL);

    Handshake.handle(
      this.actor,
      this.application,
      this.securityContext,
      this.logger,
      this.emitter,
      this.signalling,
      new SignallingActorListeners(this.actor, this.signalling)
    );
    this._handleSync();
  }

  disconnected(reason) {
    clearInterval(this.presenceInterval);
    this._update({ connection: CONNECTION_STATE.DISCONNECTED });

    if (reason === 'io client disconnect') {
      this.emitter.emit('connection-state-change', CONNECTION_STATE.ENDED);
    } else {
      this.emitter.emit(
        'connection-state-change',
        CONNECTION_STATE.DISCONNECTED
      );
    }
  }

  connecting() {
    this._update({ connection: CONNECTION_STATE.CONNECTING });
    this.emitter.emit('connection-state-change', CONNECTION_STATE.CONNECTING);
  }

  _changeControl(control) {
    this.emitter.emit('control-change', control);
    this.signalling.signalControlChange(control);
  }

  passControl() {
    if (this.actor.isCustomer()) {
      this._changeControl(ActorType.PARTNER);
    }
  }

  takeControl() {
    // Customer will always be allowed to take control for any peer
    if (this.actor.isCustomer()) {
      this._changeControl(ActorType.CUSTOMER);
    }

    const isHalfRemotePartner =
      !this.actor.isRemote && this.actor.type === ActorType.PARTNER;
    if (isHalfRemotePartner) {
      return this._changeControl(ActorType.PARTNER);
    }

    // partners can take control between themselves without asking
    if (this.control !== ActorType.CUSTOMER) {
      this._changeControl(this.actor.type);
    }
  }

  requestControl() {
    // partners can request control if a customer is driving
    if (this.control === ActorType.CUSTOMER) {
      this.signalling.signalRequestControl(this.actor.type);
    } else {
      // or take control immediately from the other partner
      this._changeControl(this.actor.type);
    }
  }

  requestControlDismiss() {
    this.emitter.emit('request-control-dismiss');
  }

  requestControlAccept() {
    this.emitter.emit('request-control-accept');
  }

  secureAction(actionPayload) {
    this.signalling.signalSecureAction({ actionPayload });
  }

  securePage({ url }) {
    if (this.actor.type === this.control) {
      this.signalling.signalSecurePage({ url });
      this._update({ isCurrentPageSecureByUserAction: true });
    }
  }

  _handleOnConfirmDialog({ type, payload }) {
    this._update({ confirm: { type, ...payload } });
    this._handleConfirmConnectionDialogOpen();
  }

  _handleOnHandshakeComplete() {
    this._update({ handshakeComplete: true });
  }

  _handleOnHandshakeRejection() {
    this._update({
      infoSnackbar: {
        isOpen: true,
        message: 'Connection refused',
      },
    });
  }

  _handleInfoSnackbarClose() {
    this._update({
      infoSnackbar: {
        isOpen: false,
      },
    });
  }

  _handleConfirmConnectionDialogOpen() {
    this._update({ confirmConnectionDialog: { isOpen: true } });
  }

  _handleConfirmConnectionDialogClose() {
    this._update({ confirmConnectionDialog: { isOpen: false } });
  }

  _handleRequestControlDialogOpen() {
    this._update({ requestControlDialog: { isOpen: true } });
  }

  _handleRequestControlDialogClose() {
    this._update({ requestControlDialog: { isOpen: false } });
  }

  onConfirmDialog(cb) {
    this.emitter.on('confirm-dialog', cb);
  }

  confirmConnection() {
    this.emitter.emit('handshake-acceptance');
    this._handleConfirmConnectionDialogClose();
    this._update({ confirm: null });
  }

  rejectConnection() {
    this.emitter.emit('handshake-rejection');
    this.emitter.emit('end-session', { from: this.actor.type });
    this._handleConfirmConnectionDialogClose();
    this._update({ confirm: null });
  }

  infoSnackbarClose() {
    this.emitter.emit('snackbar-close');
  }

  endSession() {
    this.signalling.signalSessionEnded();
    this.disableInternalStoreUpdates();
    this.emitter.emit('end-session', { from: this.actor.type });
  }

  supportAccepted(supportingPartnerId) {
    this.signalling.signalApplicationSupportedAccepted({
      supportingPartnerId,
    });
    this.emitter.emit('supported-application-accepted', {
      supportingPartnerId,
    });
  }

  onEndSession(cb) {
    this.emitter.on('end-session', cb);
  }

  onSharedConfig(cb) {
    this.emitter.on('shared-config', cb);
  }

  onSupportedApplication(cb) {
    this.emitter.on('supported-application', cb);
  }

  onSupportedApplicationAccepted(cb) {
    this.emitter.on('supported-application-accepted', cb);
  }

  onSendSyncConfig(cb) {
    this.emitter.on('send-sync-config', cb);
  }

  _sendSyncConfig() {
    // SP is not allowed to send signals, it can override
    // the PARTNER or CUSTOMER singal which is more important
    if (this.actor.isSupportingPartner()) return;

    let toBeSynced = {};
    // PARTNER shares the config
    if (this.actor.isPartner()) toBeSynced.config = this.config;
    // CUSTOMER OR MAIN_PEER shares the control
    if (this.actor.isCustomer() || this.actor.is(this.mainPeer)) {
      toBeSynced.control = this.control;
    }

    this.signalling.syncConfig(toBeSynced);
    this.logger.debug('sync config sent');
  }

  _requestSyncConfig() {
    this.logger.debug('sync config requested');
    this.signalling.syncRequest();
  }

  _handleSync() {
    this.signalling.on(Signals.SYNC_CONFIG, ({ control, config }) => {
      this.logger.debug('sync config received');

      if (control && this.control !== control) {
        this.emitter.emit('control-change', control);
      }
      if (config) this.emitter.emit('shared-config', config);

      this.logger.debug('sync config finished');
      this.synced = true;
    });

    this.signalling.on(Signals.SYNC_REQUEST, () => {
      this.logger.debug('sync request received');
      this._sendSyncConfig();
    });

    if (!this.securityContext.completed()) {
      return;
    }

    const sync = () => (this._requestSyncConfig(), this._sendSyncConfig());
    const syncRetryInterval = setInterval(
      // disable sync for half remote or if it's already finished
      () =>
        this.mainPeer === ActorType.PARTNER || this.synced ? pause() : sync(),
      SYNC_INTERVAL
    );
    const pause = () => clearInterval(syncRetryInterval);

    sync();
  }

  cleanup() {
    this.disablePersistence();
    this.disableInternalStoreUpdates();
    this.emitter.emit('cleanup');
  }
}

export const DIALOG_TYPE = {
  ALLOW_PARTNER_CONNECTION: 'ALLOW_PARTNER_CONNECTION',
  REJECT_PARTNER_CONNECTION: 'REJECT_PARTNER_CONNECTION',
};
