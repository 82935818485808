export const LandingPage = '/';
export const RemoteJoinPage = '/remotely';
export const RemotePage = '/remotely/start';
export const RemoteWithCustomerPage = '/remotely/start/supported';
export const RemoteSupportPage = '/remotely/support';
export const Restore = '/restore';
export const ApplicationPresentationPage = '/presentation';
export const EntryOrganic = '/organic';
export const BundlesPage = '/bundles';
export const EnergySelection = '/energy/selection';
export const EnergyQuote = '/energy/quote';
export const EnergyDetails = '/energy/summary';
export const Broadband = '/broadband';
export const MobileTariffSelect = '/mobile';
export const BoilerCover = '/boilerhomecover';
export const CashbackCardUpsell = '/cashbackcard';
export const BillProtector = '/billincomeprotector';
export const OrderSoFar = '/order/summary';
export const YourDetails = '/order/yourdetails';
export const OrderConfirmation = '/order/confirmation';
export const Payment = '/checkout/payment';
export const ThankYou = '/checkout/thankyou';
export const FingerprintsWithChallenge = '/checkout/verification';
export const VerificationEnd = '/checkout/verificationEnd';
export const Content = '/content/:type/:slug';
export const PartnerSSO = '/sign-in';
export const PartnerProxyLogin = '/proxy-sign-in';
export const SalesLogin = '/sign-in/sales';
export const SalesLoginIAM = '/sign-in/sales-iam';
export const Loading = '/loading';
export const Insurance = '/insurance';
export const InsuranceBoiler = `${Insurance}/boiler`;
export const InsuranceBIP = `${Insurance}/billincomeprotector`;
export const AddOns = '/addons';
export const AddOnsCashback = `${AddOns}/cashback`;
export const Preselect = '/preselect';

// entry form meta routes
export const EntryFormIntro = '/home/youraddress';
export const EntryFormAddress = '/home/youraddress/getstarted';
export const EntryFormLandline = '/home/landline';
export const EntryFormProperty = '/home/property';
export const EntryFormMeters = '/home/meters';
