import SupportingPartnerSecurityContext from './supportingSecurityContext';
import CustomerSecurityContext from './customerSecurityContext';
import PartnerSecurityContext from './partnerSecurityContext';
import PartnerWithCustomerSecurityContext from './partnerWithCustomerSecurityContext';

/*
 * Return a SecurityContext which implements the following
 * interface SecurityContext {
 *   sharedSecret(): string
 *   setSharedSecret(string)
 *   completed(): bool
 *   complete()
 *   injectState(state: any)
 *   persist()
 * }
 */
export default class {
  static forActor(actor) {
    switch (true) {
      case actor.isCustomer(): {
        return new CustomerSecurityContext();
      }
      case actor.isPartner(): {
        if (actor.isRemote) {
          return new PartnerSecurityContext();
        }
        return new PartnerWithCustomerSecurityContext();
      }
      case actor.isSupportingPartner(): {
        return new SupportingPartnerSecurityContext();
      }
      default:
        throw new Error(
          'SecurityContext::forActor: could not create instance of SecurityContext for given actor'
        );
    }
  }
}
