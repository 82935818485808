import uuidv4 from 'uuid/v4';
import { Crypto } from './crypto';
import { SessionStorage } from 'app/redux/modules/RemoteSession/v2/sessionStorage';
import SecurityContext from './securityContext';

export default class extends SecurityContext {
  constructor() {
    super();
    const { privateKey, publicKey } = Crypto.generateKeypair();
    this.privateKey = privateKey;
    this.publicKey = publicKey;
  }

  /*
   * Customer with Partner security context
   */

  generateSecret() {
    this.setSharedSecret(uuidv4());
    this.encryptSecret();
  }

  encryptSecret() {
    this._encryptedSharedSecret = Crypto.encrypt(
      this.publicKey,
      this._sharedSecret
    );
  }

  encryptedSharedSecret() {
    return this._encryptedSharedSecret;
  }

  /*
   * Life cycle methods
   * Used to infer in which stage the security handshake is
   */

  supported() {
    return this.hasBeenSupported;
  }

  supportComplete() {
    this.hasBeenSupported = true;
    this.persist();
  }

  setSupportingKey(supportingPublicKey) {
    this.supportingPublicKey = supportingPublicKey;
    this.persist();
  }

  encryptSupportingSecret() {
    this._encryptedSupportingSharedSecret = Crypto.encrypt(
      this.supportingPublicKey,
      this.sharedSecret()
    );
  }

  encryptedSupportingSharedSecret() {
    return this._encryptedSupportingSharedSecret;
  }

  /*
   * SessionStorage methods
   * Persist and recover state between refreshes
   */

  persist() {
    SessionStorage.saveSecurityContext({
      hasBeenAccepted: this.hasBeenAccepted,
      hasBeenCompleted: this.hasBeenCompleted,
      hasBeenSupported: this.hasBeenSupported,
      publicKey: this.publicKey,
      _sharedSecret: this._sharedSecret,
    });
  }

  injectState(data = {}) {
    this.hasBeenAccepted = data.hasBeenAccepted;
    this.hasBeenCompleted = data.hasBeenCompleted;
    this.hasBeenSupported = data.hasBeenSupported;
    this.publicKey = data.publicKey;
    this._sharedSecret = data._sharedSecret;
    if (this._sharedSecret && this.publicKey) {
      this.encryptSecret();
    }
  }
}
