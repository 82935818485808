import { ActorType } from 'app/redux/modules/RemoteSession/v2/actor';
import { Signals } from 'app/redux/modules/RemoteSession/v2/signalling';
import { DIALOG_TYPE } from 'app/redux/modules/RemoteSession/v2/controller';
import { HANDSHAKE_INTERVAL } from './constants';

export default class {
  constructor(
    actor,
    application,
    securityContext,
    logger,
    emitter,
    signalling,
    listener
  ) {
    this.actor = actor;
    this.application = application;
    this.securityContext = securityContext;
    this.logger = logger;
    this.emitter = emitter;
    this.signalling = signalling;
    this.listener = listener;
  }

  _request() {
    this.logger.debug('handshake requested');
    this.signalling.signalHandshakeRequest();
  }

  _accept() {
    this.signalling.signalHandshakeAccepted({
      secret: this.securityContext.encryptedSharedSecret(),
      to: ActorType.PARTNER,
    });
    this.emitter.emit('secret-agreed', {
      secret: this.securityContext.sharedSecret(),
    });
    this.securityContext.accept();
    this.logger.debug('handshake accepted');
  }

  _reject() {
    this.signalling.signalHandshakeRejected({
      to: ActorType.PARTNER,
    });
    this.logger.debug('handshake rejected');
  }

  _complete(application) {
    this.signalling.signalHandshakeCompleted({
      actor: this.actor,
      to: ActorType.PARTNER,
    });
    this.securityContext.complete();
    this.emitter.emit('send-sync-config');
    this.emitter.emit('handshake-complete', application);
    this.logger.debug('handshake completed');
  }

  _support(supportingPartnerId) {
    this.emitter.emit('supported-application', { supportingPartnerId });
  }

  handle() {
    const retry = () => {
      if (!this.securityContext.accepted()) {
        return this._request();
      }

      if (!this.securityContext.completed()) {
        return this._accept();
      }

      pause();
    };

    let handshakeRetryInterval;
    const pause = () => clearInterval(handshakeRetryInterval);
    const resume = () =>
      (handshakeRetryInterval = setInterval(retry, HANDSHAKE_INTERVAL));

    // Once we receive the share public key, generate the secret room id and
    // send to the user. We will prompt the customer to confirm that a Partner
    // will join.
    this.listener.onPartnerSignal(
      Signals.HANDSHAKE_CONTEXT,
      ({ key, phrase }) => {
        this.logger.debug('handshake context received');
        this.securityContext.setPartnerKey(key);
        this.securityContext.generateSecret();

        this.emitter.emit('confirm-dialog', {
          type: DIALOG_TYPE.ALLOW_PARTNER_CONNECTION,
          payload: { phrase },
        });

        this.logger.debug('asked to allow partner in');
        pause();
      }
    );

    // Send secret to the partner
    this.emitter.on('handshake-acceptance', () => {
      resume(); // resume retries
      this._accept();
    });

    this.emitter.on('handshake-rejection', () => {
      this._reject();
    });

    // Once the partner joins the room, we will receive the application
    // credentials and then signal to the partner that we've finished the
    // handshake
    this.listener.onPartnerSignal(
      Signals.APPLICATION_CREDENTIALS,
      ({ application }) => this._complete(application)
    );

    // If we receive details about a supported application, try to mark it on
    // the api. A signal will be sent once it is sucessfully marked.
    this.listener.onSupportingPartnerSignal(
      Signals.APPLICATION_SUPPORTED,
      ({ supportingPartnerId }) => this._support(supportingPartnerId)
    );

    this.emitter.on('cleanup', () => {
      pause();
    });

    // start
    this._request();
    // start retries
    resume();
  }
}
