import { ActorType } from 'app/redux/modules/RemoteSession/v2/actor';
import { SECURE_PATHNAMES, CONTAIN_SECURE_ACTION } from './constants';

export const isSecuredPath = (pathName) =>
  SECURE_PATHNAMES.map(({ path }) => path).includes(pathName);

export const containSecureAction = (pathName) => {
  return CONTAIN_SECURE_ACTION.includes(pathName);
};

export const getMainPeer = (actor, roster) => {
  // half remote -> mainPeer === PARTNER
  // otherwise   -> mainPeer === CUSTOMER
  let mainPeer = ActorType.CUSTOMER;

  // current actor is half remote
  if (actor.type === ActorType.PARTNER && actor.isRemote === false) {
    mainPeer = ActorType.PARTNER;
  }

  // roster is half remote
  if (
    roster[ActorType.PARTNER] &&
    roster[ActorType.PARTNER].isRemote === false
  ) {
    mainPeer = ActorType.PARTNER;
  }

  return mainPeer;
};
