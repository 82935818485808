import { Crypto } from './crypto';
import { SessionStorage } from 'app/redux/modules/RemoteSession/v2/sessionStorage';
import SecurityContext from './securityContext';

export default class extends SecurityContext {
  constructor() {
    super();
    const { privateKey, publicKey } = Crypto.generateKeypair();
    this.privateKey = privateKey;
    this.publicKey = publicKey;
  }

  /*
   * Supporting Partner with Partner security context
   */

  decryptSharedSecret(encryptedSecret) {
    if (this.completed()) {
      throw new Error("can't set shared secret, handshake already completed!");
    }

    // throw if impossible to decrypt
    this.setSharedSecret(Crypto.decrypt(this.privateKey, encryptedSecret));
  }

  context(phrase) {
    if (this.sharedSecret()) {
      throw new Error(
        "can't generate security context if the secret was already exchanged"
      );
    }

    this.persist();

    return { key: this.publicKey, phrase };
  }

  /*
   * Life cycle methods
   * Used to infer in which stage the security handshake is
   */

  accepted() {
    return this.hasBeenAccepted;
  }

  acceptedByPartner() {
    this.hasBeenAccepted = true;
    this.persist();
  }

  /*
   * SessionStorage methods
   * Persist and recover state between refreshes
   */

  persist() {
    SessionStorage.saveSecurityContext({
      phrase: this.phrase,
      hasBeenAccepted: this.hasBeenAccepted,
      hasBeenCompleted: this.hasBeenCompleted,
      privateKey: this.privateKey,
      publicKey: this.publicKey,
      _sharedSecret: this._sharedSecret,
    });
  }

  injectState(data = {}) {
    this.phrase = data.phrase;
    this.hasBeenAccepted = data.hasBeenAccepted;
    this.hasBeenCompleted = data.hasBeenCompleted;
    this.privateKey = data.privateKey;
    this.publicKey = data.publicKey;
    this._sharedSecret = data._sharedSecret;
  }
}
