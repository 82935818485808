import { connector } from 'redux/utils/connector';
import { STORE_NAME } from 'redux/modules/RemoteSession/v2/constants';
import { PartnerLoginApi } from 'redux/modules/PartnerLogin/api';
import { RemoteSessionApi } from 'redux/modules/RemoteSession/v2/api';

import SupportingForm from 'modules/Remote/SupportingForm';

const mapStateToProps = (state) => {
  const isLoggedIn = PartnerLoginApi.isPartnerLoggedIn(state);
  const remoteDisplayName = PartnerLoginApi.getRemoteDisplayName(state);
  const partnerName = PartnerLoginApi.getPartnerName(state);
  const partnerId = PartnerLoginApi.getPartnerId(state);
  const started = RemoteSessionApi.isStarted(state);
  const error = RemoteSessionApi.getError(state);

  return {
    partnerId,
    partnerName,
    isLoggedIn,
    started,
    error,
    remoteDisplayName,
  };
};

export default connector(STORE_NAME, mapStateToProps)(SupportingForm);
