import { STORE_NAME } from './constants';

export class RestoreApi {
  static getRestoreToken(state) {
    return state.getIn([STORE_NAME, 'token']);
  }

  static getRestoreStart(state) {
    return state.getIn([STORE_NAME, 'start']);
  }

  static getRestoreStore(state) {
    return state.getIn([STORE_NAME, 'store']);
  }

  static hasRestoreToken(state) {
    return Boolean(state.getIn([STORE_NAME, 'token']));
  }

  static shouldInitiateRestore(state) {
    return Boolean(
      state.getIn([STORE_NAME, 'token']) &&
        !state.getIn([STORE_NAME, 'start']) &&
        !state.getIn([STORE_NAME, 'store'])
    );
  }

  static shouldApplyRestoredApplication(state) {
    return Boolean(
      state.getIn([STORE_NAME, 'token']) &&
        state.getIn([STORE_NAME, 'start']) &&
        state.getIn([STORE_NAME, 'store'])
    );
  }
}
