import get from 'lodash/get';
import { STORE_NAME, CONNECTION_STATE } from './constants';
import { ActorType } from './actor';

export class RemoteSessionApi {
  static isStarted(state) {
    return state.getIn([STORE_NAME, 'started'], false);
  }

  static isDriver(state) {
    const actor = RemoteSessionApi.getActor(state);
    return state.getIn([STORE_NAME, 'control']) === (actor && actor.type);
  }

  static isCurrentPageSecure(state) {
    return (
      state.getIn([STORE_NAME, 'isCurrentPageSecure'], false) ||
      state.getIn([STORE_NAME, 'isCurrentPageSecureByUserAction'], false)
    );
  }

  static isRequestControlDialogOpen(state) {
    return state.getIn([STORE_NAME, 'requestControlDialog', 'isOpen'], false);
  }

  static getMainPeer(state) {
    return state.getIn([STORE_NAME, 'mainPeer'], ActorType.CUSTOMER);
  }

  static getLocalConnection(state) {
    return state.getIn([STORE_NAME, 'connection'], false);
  }

  static getActor(state) {
    const actor = state.getIn([STORE_NAME, 'actor']);
    return actor ? actor.toJS() : undefined;
  }

  static isSnackbarOpen(state) {
    return state.getIn([STORE_NAME, 'infoSnackbar', 'isOpen'], false);
  }

  static getSnackbarMessage(state) {
    return state.getIn([STORE_NAME, 'infoSnackbar', 'message'], '');
  }

  static getControlActor(state) {
    return state.getIn([STORE_NAME, 'control']);
  }

  static isMainPeerDriving(state) {
    const isDriver = RemoteSessionApi.isDriver(state);
    const actor = RemoteSessionApi.getActor(state);
    const mainPeer = RemoteSessionApi.getMainPeer(state);
    return isDriver && actor && actor.type === mainPeer;
  }

  static getRequestControlFromName(state) {
    const roster = RemoteSessionApi.getRoster(state);
    const requestFrom = state.getIn([
      STORE_NAME,
      'requestControlDialog',
      'requestFrom',
    ]);

    if (requestFrom) {
      const requestFromRoster = roster[requestFrom];
      return get(requestFromRoster, 'name', 'Partner');
    }
    return requestFrom;
  }

  static isPeerConnected(state) {
    const connectionInfo = RemoteSessionApi.getLocalConnection(state);
    return connectionInfo === CONNECTION_STATE.CONNECTED;
  }

  // TODO
  static getError() {
    return undefined;
  }

  static getConnectionFrom(state) {
    return state.getIn([STORE_NAME, 'confirm', 'phrase']);
  }

  static isConfirmConnectionDialogOpen(state) {
    return state.getIn([STORE_NAME, 'confirmConnectionDialog', 'isOpen']);
  }

  // FIXME: should be better factored to support 3 peers
  static getRemoteActorType(state) {
    // FIXME: issue with Actor class
    const localActor = RemoteSessionApi.getActor(state);
    if (!localActor) {
      return;
    }

    switch (localActor.type) {
      case ActorType.SUPPORTING_PARTNER:
        return ActorType.PARTNER;
      case ActorType.CUSTOMER:
        return ActorType.PARTNER;
      case ActorType.PARTNER: {
        if (!localActor.isRemote) {
          return ActorType.SUPPORTING_PARTNER;
        }
        return ActorType.CUSTOMER;
      }
    }
  }

  // FIXME: should not rely on handshakeComplete and the mapper should be
  // removed
  static getRoster(state) {
    const roster = state.getIn([STORE_NAME, 'roster']);
    if (!roster) {
      return {};
    }

    return roster
      .map((r) => {
        if (r.get('status') !== CONNECTION_STATE.CONNECTED) {
          return r;
        }

        // if handshake not complete, then we consider the state as connecting
        const status = state.getIn([STORE_NAME, 'handshakeComplete'])
          ? CONNECTION_STATE.CONNECTED
          : CONNECTION_STATE.CONNECTING;
        return r.set('status', status);
      })
      .toJS();
  }

  static getNameFromRoster(state, actorType) {
    return state.getIn([STORE_NAME, 'roster', actorType, 'name']);
  }

  static getPeers(state) {
    const roster = RemoteSessionApi.getRoster(state);
    return Object.values(roster);
  }

  // FIXME: should be better factored to support 3 peers
  static getRemote(state) {
    const type = RemoteSessionApi.getRemoteActorType(state);
    const status = state.getIn([STORE_NAME, 'handshakeComplete'])
      ? state.getIn([STORE_NAME, 'roster', type, 'status'])
      : CONNECTION_STATE.CONNECTING;

    return {
      type,
      status,
      name: state.getIn([STORE_NAME, 'roster', type, 'name']),
    };
  }

  // FIXME: just extracted from the controller
  static getLocal(state) {
    const peers = RemoteSessionApi.getPeers(state);

    const areAllRosterConnected =
      peers.length > 0 &&
      peers
        .filter(({ status }) => status !== CONNECTION_STATE.ENDED)
        .every(({ status }) => status === CONNECTION_STATE.CONNECTED);

    const connection = state.getIn([STORE_NAME, 'connection']);
    let status = connection;
    if (connection === CONNECTION_STATE.DISCONNECTED) {
      status = CONNECTION_STATE.DISCONNECTED;
    } else if (connection === CONNECTION_STATE.CONNECTING) {
      status = CONNECTION_STATE.CONNECTING;
    } else {
      status = areAllRosterConnected
        ? CONNECTION_STATE.CONNECTED
        : CONNECTION_STATE.CONNECTING;
    }

    const actor = RemoteSessionApi.getActor(state);
    return {
      type: actor && actor.type,
      status,
      isDriver: RemoteSessionApi.isDriver(state),
    };
  }

  static getConfig(state, key, defaultValue = false) {
    return state.getIn([STORE_NAME, 'config', key], defaultValue);
  }

  static isHandshakeComplete(state) {
    return state.getIn([STORE_NAME, 'handshakeComplete'], false);
  }

  static getPreviousControl(state) {
    return state.getIn([STORE_NAME, 'previousControl'], null);
  }

  static getPreviousPageSecure(state) {
    return state.getIn([STORE_NAME, 'previousPageSecure'], null);
  }

  static isHalfRemote(state) {
    const actor = state.getIn([STORE_NAME, 'actor']);
    if (!actor) return false;
    const roster = {
      ...state.getIn([STORE_NAME, 'roster']),
      [actor.type]: actor,
    };

    const isPartnerRemote =
      roster[ActorType.PARTNER] && roster[ActorType.PARTNER].isRemote === false;
    const isSupportingRemote =
      roster[ActorType.SUPPORTING_PARTNER] &&
      roster[ActorType.SUPPORTING_PARTNER].isRemote === true;
    return !isPartnerRemote && isSupportingRemote;
  }
}
