import {
  STORE_NAME as YourDetailsName,
  ABOUT_YOU_SECTION,
  DIRECT_DEBIT_SECTION,
} from 'redux/modules/YourDetails/constants';
import {
  directDebitSection,
  aboutYouSection,
} from 'redux/modules/YourDetails/form';
import { STORE_NAME as PartnerLoginName } from 'redux/modules/PartnerLogin/constants';
import { STORE_NAME as PersistenceName } from 'redux/modules/Persistence/constants';
import { STORE_NAME as LandingPageName } from 'redux/modules/LandingPage/constants';
import { STORE_NAME as RemoteSessionName } from 'redux/modules/RemoteSession/v2/constants';
import { STORE_NAME as FeatureFlags } from 'redux/modules/FeatureFlags/constants';
import { STORE_NAME as Notifications } from 'redux/modules/Notifications/constants';
import { STORE_NAME as RestoreName } from 'redux/modules/Restore/constants';
import { Actor } from './actor';
import { RemoteSessionApi } from './api';

const maskDigits = (value) => value?.replace(/[0-9]/gi, '0');
const maskPassword = (value) => (value ? '***********' : '');
const getFieldPath = (formId, fieldId) => [
  YourDetailsName,
  'forms',
  formId,
  'values',
  fieldId,
];

const keysToMask = [
  {
    path: getFieldPath(
      DIRECT_DEBIT_SECTION,
      directDebitSection.DIRECT_DEBIT_SORT_CODE
    ),
    fn: maskDigits,
  },
  {
    path: getFieldPath(
      DIRECT_DEBIT_SECTION,
      directDebitSection.DIRECT_DEBIT_ACCOUNT_NUMBER
    ),
    fn: maskDigits,
  },
  {
    path: getFieldPath(ABOUT_YOU_SECTION, aboutYouSection.ABOUT_YOU_PASSWORD),
    fn: maskPassword,
  },
];

export const keysToFilterCommon = [
  [PersistenceName],
  [PartnerLoginName],
  [RemoteSessionName],
  [FeatureFlags],
  [Notifications],
];

export const keysToFilterPartner = [
  ...keysToFilterCommon,
  ...keysToMask.map(({ path }) => path),
  [LandingPageName, 'appointmentType'],
  [RestoreName],
];

export const filterPaths = ({ type, isRemote }) => {
  if (!type) {
    return [];
  }

  const actor = new Actor({ type, isRemote });
  // if SP or remote Partner
  if ((actor.isPartner() && actor.isRemote) || actor.isSupportingPartner()) {
    return keysToFilterPartner;
  }

  // for partner if half remote (isRemote = false)
  return keysToFilterCommon;
};

export const deleteKeys = (state, keys) => {
  for (const path of keys) {
    state = state.deleteIn(path);
  }
  return state;
};

export const mask = (state, keys) => {
  for (const field of keys) {
    state = state.setIn(field.path, field.fn(state.getIn(field.path)));
  }
  return state;
};

export const filter = (
  state,
  commonFilterKeys = keysToFilterCommon,
  partnerFilterKeys = keysToFilterPartner,
  maskKeys = keysToMask
) => {
  let newState = state;
  const mainPeer = RemoteSessionApi.getMainPeer(state);
  const actorJS = RemoteSessionApi.getActor(state);
  const actor = new Actor(actorJS);

  newState = deleteKeys(newState, commonFilterKeys);
  if (
    actor.isSupportingPartner() || // delete keys for SP
    (actor.isPartner() && !actor.is(mainPeer)) // and for partner if not half remote
  ) {
    newState = deleteKeys(newState, partnerFilterKeys);
  }

  // mask keys from
  // * Customer
  // * Partner if half remote
  if (actor.isCustomer() || actor.is(mainPeer)) {
    newState = mask(newState, maskKeys);
  }

  return newState;
};

export default filter;
