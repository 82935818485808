import * as types from './types';

export const saveState = (email) => ({
  type: types.APP_STATE_SAVE,
  email,
});

export const restoreState = (token, postcode) => ({
  type: types.APP_STATE_RESTORE,
  postcode,
  token,
});

export const restoreStateSuccess = (state) => ({
  type: types.APP_STATE_RESTORE_SUCCESS,
  state,
});

export const restoreStateFailure = (error) => ({
  type: types.APP_STATE_RESTORE_FAILURE,
  error,
});

export const setToken = (token) => ({
  type: types.APP_RESTORE_TOKEN_SET,
  token,
});

export const setStart = (start) => ({
  type: types.APP_RESTORE_START_SET,
  start,
});

export const setStore = (store) => ({
  type: types.APP_RESTORE_STORE_SET,
  store,
});

export const initRestore = (start) => ({
  type: types.APP_RESTORE_INIT,
  start,
});

export const applyRestoredApplication = () => ({
  type: types.APP_RESTORE_APPLY,
});
