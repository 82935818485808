export const STORE_NAME = 'Restore';

export const DEFAULT_ERROR_REASON = 'unspecified';
export const DEFAULT_ERROR_MESSAGE =
  'Sorry, something went wrong. Please try again later.';

export const EXPIRED_ERROR_REASON = 'expired';
export const EXPIRED_ERROR_MESSAGE =
  'Sorry, it looks like the link you are trying to access is expired.';

export const NOT_FOUND_ERROR_REASON = 'not-found';
export const NOT_FOUND_ERROR_MESSAGE =
  'Sorry, we are not able to retrieve the quote currently. Please try again later.';

export const VERSION_ERROR_REASON = 'app-store-version-mismatch';
export const VERSION_ERROR_MESSAGE = 'Sorry, this quote is no longer valid.';
