import { ActorType } from 'redux/modules/RemoteSession/v2/actor';
import { actionsToFilter } from 'redux/modules/RemoteSession/v2/middlewareFilter';
import { RemoteSessionApi } from 'redux/modules/RemoteSession/v2/api';

export const _remoteSessionMiddleware = (actor, control) => {
  let _actor = actor;
  let _control = control;
  let _remote = !!(actor && control);

  return {
    handler: (store) => (next) => (action) => {
      if (!_remote) return next(action);

      const isControlling = _control === _actor;
      const mainPeer = RemoteSessionApi.getMainPeer(store.getState());
      const isBlacklisted = actionsToFilter(mainPeer, _actor).includes(
        action.type
      );
      return (!isBlacklisted || isControlling) && next(action);
    },
    setControl: (control) => (_control = control),
    setActor: (actor) => (_actor = actor),
    enable: () => {
      _remote = true;
      // If no control is set, always assume customer is in control
      _control = _control || ActorType.CUSTOMER;
    },
    disable: () => {
      _actor = undefined;
      _control = undefined;
      _remote = false;
    },
  };
};

export default Object.freeze(_remoteSessionMiddleware());
