import forge from 'node-forge';

forge.options.usePureJavaScript = true;

export class Crypto {
  static generateKeypair() {
    const { publicKey, privateKey } = forge.pki.rsa.generateKeyPair({
      bits: 1024,
    });

    return {
      publicKey: forge.pki.publicKeyToPem(publicKey),
      privateKey: forge.pki.privateKeyToPem(privateKey),
    };
  }

  static decrypt(privateKey, message) {
    const decoded = forge.pki.privateKeyFromPem(privateKey).decrypt(message);
    return forge.util.decodeUtf8(decoded);
  }

  static encrypt(publicKey, message) {
    return forge.pki
      .publicKeyFromPem(publicKey)
      .encrypt(forge.util.encodeUtf8(message));
  }
}
