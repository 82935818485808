import { ActorType } from 'app/redux/modules/RemoteSession/v2/actor';
import { Signals } from 'app/redux/modules/RemoteSession/v2/signalling';
import { HANDSHAKE_INTERVAL } from './constants';

export default class {
  constructor(
    actor,
    application,
    securityContext,
    logger,
    emitter,
    signalling,
    listener
  ) {
    this.actor = actor;
    this.application = application;
    this.securityContext = securityContext;
    this.logger = logger;
    this.emitter = emitter;
    this.signalling = signalling;
    this.listener = listener;
  }

  _request() {
    this.logger.debug('handshake requested');
    try {
      this.signalling.signalHandshakeContext({
        ...this.securityContext.context(this.actor.name),
        to: ActorType.PARTNER,
      });
      this.logger.debug('handshake context sent');
    } catch (err) {
      this.logger.debug(err, 'failed to send handshake context');
      // ignore if this is failing because handshake was accepted
    }
  }

  _accepted(secret) {
    this.logger.debug('handshake acceptance received');
    // received when customer/partner accepted handshake
    try {
      this.securityContext.decryptSharedSecret(secret);
      this.logger.debug('shared secret decrypted');
    } catch (err) {
      this.logger.debug(err, 'failed to decrypt shared secret');
      // ignore for now, this is to prevent replays
    }

    // at this stage we know how to communicate with the other side securely
    this.emitter.emit('secret-agreed', {
      secret: this.securityContext.sharedSecret(),
    });

    setTimeout(this._credsRequest.bind(this), 200);
    this.securityContext.acceptedByPartner();
  }

  _credsRequest() {
    this.signalling.signalApplicationCredentialsRequest();
  }

  _supportApplication(application) {
    this.application = application;
    this.signalling.signalApplicationSupported({
      supportingPartnerId: this.actor.id,
    });
  }

  _completed(application) {
    this.signalling.signalHandshakeCompleted({ actor: this.actor });
    this.securityContext.complete();
    this.emitter.emit('handshake-complete', application);
    this.logger.debug('handshake completed');
  }

  handle() {
    let handshakeRetryInterval;

    const retry = () => {
      if (!this.securityContext.accepted()) {
        return this._request();
      }

      if (!this.securityContext.completed()) {
        return this._credsRequest();
      }

      pause();
    };

    const pause = () => clearInterval(handshakeRetryInterval);
    const resume = () =>
      (handshakeRetryInterval = setInterval(retry, HANDSHAKE_INTERVAL));

    // Upon receiving the shared room, supporting partner joins it and wait for
    // further messages
    this.listener.onPartnerSignal(Signals.HANDSHAKE_ACCEPTED, ({ secret }) =>
      this._accepted(secret)
    );

    // Once we receive the application details, it means that we successfully
    // joined the shared room and received all required data from partner. If we
    // miss the signal, we will be requesting it again.
    this.listener.onPartnerSignal(
      Signals.APPLICATION_CREDENTIALS,
      ({ application }) => this._supportApplication(application)
    );

    // If this signal was received, customer/partner side would have accepted and marked
    // the application as supported, hence finishing the process.
    this.signalling.on(Signals.APPLICATION_SUPPORTED_ACCEPTED, () =>
      this._completed(this.application)
    );

    this.emitter.on('cleanup', () => {
      pause();
    });

    // start
    this._request();
    // start retries
    resume();
  }
}
