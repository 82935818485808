import { useSelector } from 'react-redux';
import { ApplicationApi } from 'redux/modules/App/api';
import { RemoteSessionApi } from '../api';

export const useIsButtonDisabled = () => {
  const isRemoteSession = useSelector(ApplicationApi.isRemoteSession);
  const actor = useSelector(RemoteSessionApi.getActor);
  const control = useSelector(RemoteSessionApi.getControlActor);
  return !!(isRemoteSession && actor && actor.type !== control);
};
