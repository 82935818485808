import { connector } from 'redux/utils/connector';
import CustomerFormComponent from 'modules/Remote/CustomerForm';
import { PartnerLoginApi } from 'redux/modules/PartnerLogin/api';
import { ApplicationApi } from 'redux/modules/App/api';

import { STORE_NAME } from '../constants';
import { startCustomerSession } from '../actions';

export default connector(
  STORE_NAME,
  (state) => ({
    isPartnerLoggedIn: PartnerLoginApi.isPartnerLoggedIn(state),
    isRemoteSession: ApplicationApi.isRemoteSession(state),
  }),
  {
    startCustomerSession,
  }
)(CustomerFormComponent);
