import { EventEmitter } from 'eventemitter3';

export class Store {
  constructor() {
    this.emitter = new EventEmitter();
  }

  onLocalUpdate(cb) {
    this.emitter.on('local-update', cb);
  }

  localUpdate(update) {
    this.emitter.emit('local-update', update);
  }

  updateStore(update) {
    this.emitter.emit('update-received', update);
  }

  onRemoteUpdate(cb) {
    this.emitter.on('update-received', cb);
  }
}
