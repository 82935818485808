import uuidv4 from 'uuid/v4';
import { Crypto } from './crypto';
import { SessionStorage } from 'app/redux/modules/RemoteSession/v2/sessionStorage';
import SecurityContext from './securityContext';

export default class extends SecurityContext {
  constructor() {
    super();
  }

  /*
   * Customer with Partner security context
   */

  setPartnerKey(partnerPublicKey) {
    this.partnerPublicKey = partnerPublicKey;
    this.persist();
  }

  generateSecret() {
    this.setSharedSecret(uuidv4());
    this.encryptSecret();
  }

  encryptSecret() {
    this._encryptedSharedSecret = Crypto.encrypt(
      this.partnerPublicKey,
      this._sharedSecret
    );
  }

  encryptedSharedSecret() {
    return this._encryptedSharedSecret;
  }

  /*
   * Life cycle methods
   * Used to infer in which stage the security handshake is
   */

  accepted() {
    return this.hasBeenAccepted;
  }

  accept() {
    this.hasBeenAccepted = true;
    this.persist();
  }

  /*
   * SessionStorage methods
   * Persist and recover state between refreshes
   */

  persist() {
    SessionStorage.saveSecurityContext({
      hasBeenAccepted: this.hasBeenAccepted,
      hasBeenCompleted: this.hasBeenCompleted,
      partnerPublicKey: this.partnerPublicKey,
      _sharedSecret: this._sharedSecret,
    });
  }

  injectState(data = {}) {
    this.hasBeenAccepted = data.hasBeenAccepted;
    this.hasBeenCompleted = data.hasBeenCompleted;
    this.partnerPublicKey = data.partnerPublicKey;
    this._sharedSecret = data._sharedSecret;
    if (this._sharedSecret && this.partnerPublicKey) {
      this.encryptSecret();
    }
  }
}
