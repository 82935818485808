import { EventEmitter } from 'eventemitter3';

const messages = {
  CURSOR_UPDATE: 'cursor-update',
  SCROLL_UPDATE: 'scroll-update',
  STORE_UPDATE: 'store-update',
};

export class DataChannel {
  constructor(actor) {
    this.actor = actor.type;
    this.emitter = new EventEmitter();
  }

  handleMessage(message) {
    switch (message.type) {
      case messages.CURSOR_UPDATE:
        return this.emitter.emit('cpu', message);
      case messages.SCROLL_UPDATE:
        return this.emitter.emit('spu', message);
      case messages.STORE_UPDATE:
        return this.emitter.emit('su', message);
    }
  }

  onData(cb) {
    this.emitter.on('data', cb);
  }

  _send(type, payload = {}) {
    this.emitter.emit('data', {
      type,
      ...payload,
    });
  }

  sendCursorPositionUpdate(cursorUpdate) {
    this._send(messages.CURSOR_UPDATE, cursorUpdate);
  }

  sendScrollPositionUpdate(scrollUpdate) {
    this._send(messages.SCROLL_UPDATE, scrollUpdate);
  }

  sendStoreUpdate(storeUpdate) {
    this._send(messages.STORE_UPDATE, storeUpdate);
  }

  onCursorPositionUpdate(cb) {
    this.emitter.on('cpu', cb);
  }

  onScrollPositionUpdate(cb) {
    this.emitter.on('spu', cb);
  }

  onStoreUpdate(storeUpdate) {
    this.emitter.on('su', storeUpdate);
  }
}
