export const ActorType = {
  PARTNER: 'PARTNER',
  SUPPORTING_PARTNER: 'SUPPORTING_PARTNER',
  CUSTOMER: 'CUSTOMER',
};

export class Actor {
  constructor({ type, name, id, isRemote = true }) {
    this.type = type;
    this.name = name;
    this.id = id;
    this.isRemote = isRemote;
  }

  isPartner() {
    return this.is(ActorType.PARTNER);
  }

  isCustomer() {
    return this.is(ActorType.CUSTOMER);
  }

  isSupportingPartner() {
    return this.is(ActorType.SUPPORTING_PARTNER);
  }

  is(type) {
    return this.type == type;
  }

  toJS() {
    return {
      type: this.type,
      name: this.name,
      id: this.id,
      isRemote: this.isRemote,
    };
  }
}
