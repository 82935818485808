/*
 * Abstract class for SecurityContext
 * Contains some basic methods and enforces a specific interface
 */
export default class {
  /*
   * Returns the shared secret room ID used by all peers when communicating
   * secure data (state, viewport, cursors etc)
   */
  sharedSecret() {
    return this._sharedSecret;
  }

  /*
   * Sets shared secret room ID used to communicate secure data
   * @param {string} secret Secret room ID
   */
  setSharedSecret(secret) {
    this._sharedSecret = secret;
    this.persist();
  }

  /*
   * Returns if the handshake security process was completed
   */
  completed() {
    return this.hasBeenCompleted;
  }

  /*
   * Sets handshake security process as completed
   */
  complete() {
    this.hasBeenCompleted = true;
    this.persist();
  }

  /*
   * Recovers the Security Context internal state
   * @param {object} data Object containing state
   */
  injectState() {
    throw new Error('not implemented');
  }

  /*
   * Persists the Security Context in a determined storage
   */
  persist() {
    throw new Error('not implemented');
  }
}
