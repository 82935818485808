import { EventEmitter } from 'eventemitter3';
import { ActorType } from 'app/redux/modules/RemoteSession/v2/actor';

export const Signals = {
  CONTROL_CHANGE: 'CONTROL_CHANGE',
  PRESENCE: 'PRESENCE',
  HANDSHAKE_REQUEST: 'HANDSHAKE_REQUEST',
  HANDSHAKE_CONTEXT: 'HANDSHAKE_CONTEXT',
  HANDSHAKE_ACCEPTED: 'HANDSHAKE_ACCEPTED',
  HANDSHAKE_REJECTED: 'HANDSHAKE_REJECTED',
  HANDSHAKE_COMPLETED: 'HANDSHAKE_COMPLETED',
  APPLICATION_CREDENTIALS: 'APPLICATION_CREDENTIALS',
  APPLICATION_CREDENTIALS_REQUEST: 'APPLICATION_CREDENTIALS_REQUEST',
  APPLICATION_SUPPORTED: 'APPLICATION_SUPPORTED',
  APPLICATION_SUPPORTED_ACCEPTED: 'APPLICATION_SUPPORTED_ACCEPTED',
  REQUEST_CONTROL: 'REQUEST_CONTROL',
  SESSION_ENDED: 'SESSION_ENDED',
  SECURE_ACTION: 'SECURE_ACTION',
  SECURE_PAGE: 'SECURE_PAGE',
  SYNC_REQUEST: 'SYNC_REQUEST',
  SYNC_CONFIG: 'SYNC_CONFIG',
};

export const Presence = {
  CONNECTED: 'CONNECTED',
};

export class Signalling {
  constructor(logger) {
    this.logger = logger;
    this.emitter = new EventEmitter();
  }

  _send(type, payload = {}) {
    this.emitter.emit('signal', {
      type,
      ...payload,
    });
  }

  // handles incoming signals
  handle(m) {
    this.logger.debug(`handle ${m.type}`, m);
    this.emitter.emit(m.type, m);
  }

  on(signal, callback) {
    if (!Signals[signal]) {
      return; // invalid signal
    }

    this.emitter.on(signal, callback);
  }

  onSignal(callback) {
    this.emitter.on('signal', callback);
  }

  signalPresence(actor) {
    this._send(Signals.PRESENCE, { actor, presence: Presence.CONNECTED });
  }

  signalControlChange(control) {
    this.logger.debug('signal control change', control);
    this._send(Signals.CONTROL_CHANGE, { control, secure: true });
  }

  signalRequestControl(control) {
    this.logger.debug('signal request control', control);
    this._send(Signals.REQUEST_CONTROL, { control, secure: true });
  }

  signalHandshakeContext(context) {
    this.logger.debug('signal handshake context', context);
    this._send(Signals.HANDSHAKE_CONTEXT, context);
  }

  signalHandshakeRequest() {
    this.logger.debug('signal handshake request');
    this._send(Signals.HANDSHAKE_REQUEST);
  }

  signalHandshakeAccepted(payload) {
    this.logger.debug('signal handshake accepted', payload);
    this._send(Signals.HANDSHAKE_ACCEPTED, payload);
  }

  signalHandshakeRejected(payload) {
    this.logger.debug('signal handshake denied', payload);
    this._send(Signals.HANDSHAKE_REJECTED, payload);
  }

  signalHandshakeCompleted(peerInfo) {
    this.logger.debug('signal handshake completed');
    this._send(Signals.HANDSHAKE_COMPLETED, peerInfo);
  }

  signalApplicationCredentials(application) {
    this.logger.debug('signal application credentials', application);
    this._send(Signals.APPLICATION_CREDENTIALS, application);
  }

  signalApplicationCredentialsRequest() {
    this.logger.debug('signal application credentials request');
    this._send(Signals.APPLICATION_CREDENTIALS_REQUEST);
  }

  signalSessionEnded() {
    this.logger.debug('signal application ended');
    this._send(Signals.SESSION_ENDED);
  }

  syncRequest() {
    this.logger.debug('signal sync request');
    this._send(Signals.SYNC_REQUEST);
  }

  syncConfig(config) {
    this.logger.debug('signal sync config');
    this._send(Signals.SYNC_CONFIG, config);
  }

  signalApplicationSupported(partnerDetails) {
    this.logger.debug('signal application supported', partnerDetails);
    this._send(Signals.APPLICATION_SUPPORTED, partnerDetails);
  }

  signalApplicationSupportedAccepted(partnerDetails) {
    this.logger.debug('signal application supported accepted');
    this._send(Signals.APPLICATION_SUPPORTED_ACCEPTED, partnerDetails);
  }

  signalSecureAction(actionPayload) {
    this.logger.debug('signal secure action');
    this._send(Signals.SECURE_ACTION, actionPayload);
  }

  signalSecurePage(url) {
    this.logger.debug('signal secure page');
    this._send(Signals.SECURE_PAGE, url);
  }
}

export class SignallingActorListeners {
  constructor(actor, signalling) {
    this.actor = actor;
    this.signalling = signalling;
  }

  onCustomerSignal(signal, cb) {
    cb = cb.bind(this);
    return this.signalling.on(signal, (params) => {
      if (params.from !== ActorType.CUSTOMER) return;
      if (params.to && params.to !== this.actor.type) return;

      return cb(params);
    });
  }

  onPartnerSignal(signal, cb) {
    cb = cb.bind(this);
    return this.signalling.on(signal, (params) => {
      if (params.from !== ActorType.PARTNER) return;
      if (params.to && params.to !== this.actor.type) return;

      return cb(params);
    });
  }

  onSupportingPartnerSignal(signal, cb) {
    cb = cb.bind(this);
    return this.signalling.on(signal, (params) => {
      if (params.from !== ActorType.SUPPORTING_PARTNER) return;
      if (params.to && params.to !== this.actor.type) return;

      return cb(params);
    });
  }
}
