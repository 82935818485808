import { call, takeLatest, put, select, delay } from 'redux-saga/effects';
import * as types from './types';
import * as routesTypes from 'redux/modules/Routes/types';
import * as actions from './actions';
import * as appActions from 'redux/modules/App/actions';
import { initialState } from './reducer';
import { RestoreApi } from './api';
import { LandingPageApi } from 'redux/modules/LandingPage/api';
import getRestoreState from './services/getRestoreState';
import { serverLoad } from 'redux/modules/Persistence/actions';
import { getCurrentSession } from 'redux/modules/RemoteSession/v2/saga';
import { ActorType } from 'redux/modules/RemoteSession/v2/actor';
import browserHistory from 'components/root/browserHistory';
import { getClient } from 'app/lib/analytics';
import { REDUX_STORE_VERSION } from 'app/redux';
import { EVENTS, EVENT_TYPE } from 'app/lib/analytics/constants';

import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_ERROR_REASON,
  EXPIRED_ERROR_MESSAGE,
  EXPIRED_ERROR_REASON,
  NOT_FOUND_ERROR_MESSAGE,
  NOT_FOUND_ERROR_REASON,
  VERSION_ERROR_MESSAGE,
  VERSION_ERROR_REASON,
} from './constants';

class VersionMismatchError extends Error {}

function* restoreState() {
  const token = yield select(RestoreApi.getRestoreToken);
  const analytics = getClient().client;
  try {
    const { data } = yield call(getRestoreState, token);

    const {
      appState: { redux, _version: restoredStoreVersion },
      application,
      applicationToken,
    } = data;

    if (REDUX_STORE_VERSION !== restoredStoreVersion) {
      throw new VersionMismatchError(
        `Restored snapshot version ${restoredStoreVersion} is not compatible with current app store version ${REDUX_STORE_VERSION}`
      );
    }

    yield put(actions.restoreStateSuccess());

    const parsed = JSON.parse(redux);
    const restoredState = {
      ...parsed,
      App: {
        ...parsed.App,
        // update restored application data with new
        // token and application ID
        token: applicationToken,
        application: {
          ...parsed.application,
          id: application.uuid,
        },
        remote: {},
      },
      // prevent application timeout by resetting persistence timestamp to
      // time of request
      Persistence: {
        ...parsed.Persistence,
        timestamp: Date.now(),
      },
      RemoteSession: {},
      Restore: initialState.toJS(),
    };

    const startAction = yield select(RestoreApi.getRestoreStart);
    yield put(actions.setStore(restoredState));
    browserHistory.push('/');
    yield put(startAction);
  } catch (err) {
    const errorType = err?.response?.data?.type;
    let errorMessage;
    let trackReason;
    switch (true) {
      case err instanceof VersionMismatchError:
        errorMessage = VERSION_ERROR_MESSAGE;
        trackReason = VERSION_ERROR_REASON;
        break;
      case errorType === 'SnapshotExpiredError':
        errorMessage = EXPIRED_ERROR_MESSAGE;
        trackReason = EXPIRED_ERROR_REASON;
        break;
      case errorType === 'ApplicationNotFoundError' ||
        errorType === 'SnapshotNotFoundError':
        errorMessage = NOT_FOUND_ERROR_MESSAGE;
        trackReason = NOT_FOUND_ERROR_REASON;
        break;
      default:
        errorMessage = DEFAULT_ERROR_MESSAGE;
        trackReason = DEFAULT_ERROR_REASON;
        break;
    }
    yield put(actions.restoreStateFailure(errorMessage));
    analytics.track(EVENTS.APPLICATION_RESTORE_FAILED, {
      event_type: EVENT_TYPE.APPLICATION,
      reason: trackReason,
    });
  }
}

function* initRestore(action) {
  yield put(actions.setStart(action.start));
  browserHistory.push('/restore/start');
}

function* applyRestoredApplication() {
  const analytics = getClient().client;
  const restoredStore = yield select(RestoreApi.getRestoreStore);

  yield put(serverLoad.success(restoredStore));
  yield put(appActions.setReadOnly());
  browserHistory.push('/order/summary');

  analytics.track(EVENTS.APPLICATION_RESTORE_SUCCESSFUL, {
    event_type: EVENT_TYPE.APPLICATION,
  });
}

function* restoreRemoteApplication(action) {
  const remoteSession = getCurrentSession();
  const restoredStore = yield select(RestoreApi.getRestoreStore);
  const startAction = yield select(RestoreApi.getRestoreStart);
  const isBeingSupportedRemotely = yield select(
    LandingPageApi.isBeingSupportedRemotely
  );
  // remote session case
  // - both parties are connected and customer's side has started the
  //   appointment by opening the presentation (seems to be the most reliable)
  // - skip restore if nothing to restore and not remote appointment type
  // - the arbitrary delays seems to be the only way to fix the flakiness due to
  //   race conditions between the parties connected
  if (
    restoredStore &&
    (startAction.remotePartnerId || isBeingSupportedRemotely) &&
    action.type === routesTypes.ROUTE_CHANGE &&
    action.path === '/presentation'
  ) {
    yield delay(1000);
    remoteSession.controller._changeControl(ActorType.PARTNER);
    yield delay(1000);
    yield put(actions.applyRestoredApplication());
  }
}

export function* combinedSagas() {
  yield takeLatest(types.APP_STATE_RESTORE, restoreState);
  yield takeLatest(types.APP_RESTORE_INIT, initRestore);
  yield takeLatest(types.APP_RESTORE_APPLY, applyRestoredApplication);
  yield takeLatest(routesTypes.ROUTE_CHANGE, restoreRemoteApplication);
}
