import { ActorType } from 'app/redux/modules/RemoteSession/v2/actor';
import { Signals } from 'app/redux/modules/RemoteSession/v2/signalling';

export default class {
  constructor(
    actor,
    application,
    securityContext,
    logger,
    emitter,
    signalling,
    listener
  ) {
    this.actor = actor;
    this.application = application;
    this.securityContext = securityContext;
    this.logger = logger;
    this.emitter = emitter;
    this.signalling = signalling;
    this.listener = listener;
  }

  _context() {
    this.logger.debug('handshake request received');
    // received a request from handshake from customer
    try {
      this.signalling.signalHandshakeContext({
        ...this.securityContext.context(this.actor.name),
        to: ActorType.CUSTOMER,
      });
      this.logger.debug('handshake context sent');
    } catch (err) {
      this.logger.debug(err, 'failed to send handshake context');
      // ignore if this is failing because handshake was accepted
    }
  }

  _accepted(secret) {
    this.logger.debug('handshake acceptance received');
    // received when customer accepted handshake
    try {
      this.securityContext.decryptSharedSecret(secret);
      this.logger.debug('shared secret decrypted');
    } catch (err) {
      this.logger.debug(err, 'failed to decrypt shared secret');
      // ignore for now, this is to prevent replays
    }

    // at this stage we know how to communicate with the other side securely
    this.emitter.emit('secret-agreed', {
      secret: this.securityContext.sharedSecret(),
    });

    setTimeout(() => {
      // this needs to happen on next tick after the join event happens
      this.signalling.signalApplicationCredentials({
        application: this.application,
        to: ActorType.CUSTOMER,
        secure: true,
      });
      this.logger.debug('application credentials sent');
    }, 200);
  }

  _rejected() {
    this.emitter.emit('handshake-rejection');
    this.logger.debug('handshake acceptance rejected');
  }

  _completed() {
    this.logger.debug('handshake complete');
    this.signalling.signalHandshakeCompleted({
      actor: this.actor,
      to: ActorType.CUSTOMER,
    });
    this.emitter.emit('send-sync-config');
    this.securityContext.complete();
    this.emitter.emit('handshake-complete');
  }

  handle() {
    // Customer joined the public room and is requesting a shared secret (public
    // key) to be used to send the secret room details
    this.listener.onCustomerSignal(Signals.HANDSHAKE_REQUEST, () =>
      this._context()
    );

    // Once Customer accepted us to join the application, Partner will receive
    // an encrypted secret room id. Partner will join the secret room and send
    // the application credentials through it
    this.listener.onCustomerSignal(Signals.HANDSHAKE_ACCEPTED, ({ secret }) =>
      this._accepted(secret)
    );

    // Customer can reject the handshake by clicking the `Reject` button. The popup
    // will be shown to the Partner, but they will stay in a `connecting` state
    this.listener.onCustomerSignal(Signals.HANDSHAKE_REJECTED, () =>
      this._rejected()
    );

    // Handshake complete, no more actions required
    this.listener.onCustomerSignal(Signals.HANDSHAKE_COMPLETED, () =>
      this._completed()
    );
  }
}
