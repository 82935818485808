import { Crypto } from './crypto';
import { SessionStorage } from 'app/redux/modules/RemoteSession/v2/sessionStorage';
import SecurityContext from './securityContext';

export default class extends SecurityContext {
  constructor() {
    super();
    const { privateKey, publicKey } = Crypto.generateKeypair();
    this.privateKey = privateKey;
    this.publicKey = publicKey;
  }

  /*
   * Partner with Customer security context
   */

  decryptSharedSecret(encryptedSecret) {
    if (this.completed()) {
      throw new Error("can't set shared secret, handshake already completed!");
    }

    // throw if impossible to decrypt
    this.setSharedSecret(Crypto.decrypt(this.privateKey, encryptedSecret));
  }

  context(phrase) {
    if (this.sharedSecret()) {
      throw new Error(
        "can't generate security context if the secret was already exchanged"
      );
    }

    this.persist();

    return { key: this.publicKey, phrase };
  }

  /*
   * Partner with Supporting Partner security context
   */

  setSupportingKey(supportingPublicKey) {
    this.supportingPublicKey = supportingPublicKey;
    this.persist();
  }

  encryptSupportingSecret() {
    this._encryptedSupportingSharedSecret = Crypto.encrypt(
      this.supportingPublicKey,
      this.sharedSecret()
    );
  }

  encryptedSupportingSharedSecret() {
    return this._encryptedSupportingSharedSecret;
  }

  /*
   * Life cycle methods
   * Used to infer in which stage the security handshake is
   */

  supported() {
    return this.hasBeenSupported;
  }

  supportComplete() {
    this.hasBeenSupported = true;
    this.persist();
  }

  /*
   * SessionStorage methods
   * Persist and recover state between refreshes
   */

  persist() {
    SessionStorage.saveSecurityContext({
      phrase: this.phrase,
      hasBeenCompleted: this.hasBeenCompleted,
      hasBeenSupported: this.hasBeenSupported,
      privateKey: this.privateKey,
      publicKey: this.publicKey,
      _sharedSecret: this._sharedSecret,
    });
  }

  injectState(data = {}) {
    this.phrase = data.phrase;
    this.hasBeenCompleted = data.hasBeenCompleted;
    this.hasBeenSupported = data.hasBeenSupported;
    this.privateKey = data.privateKey;
    this.publicKey = data.publicKey;
    this.supportingPublicKey = data.supportingPublicKey;
    this._sharedSecret = data._sharedSecret;
  }
}
