import { ActorType } from 'app/redux/modules/RemoteSession/v2/actor';
import { Signals } from 'app/redux/modules/RemoteSession/v2/signalling';
import { DIALOG_TYPE } from 'app/redux/modules/RemoteSession/v2/controller';

export default class {
  constructor(
    actor,
    application,
    securityContext,
    logger,
    emitter,
    signalling,
    listener
  ) {
    this.actor = actor;
    this.application = application;
    this.securityContext = securityContext;
    this.logger = logger;
    this.emitter = emitter;
    this.signalling = signalling;
    this.listener = listener;
  }

  _context(key, phrase) {
    if (
      this.securityContext.supported() ||
      !this.securityContext.sharedSecret()
    ) {
      return;
    }

    this.logger.debug('handshake context received');
    this.securityContext.setSupportingKey(key);
    this.securityContext.encryptSupportingSecret();

    this.emitter.emit('confirm-dialog', {
      type: DIALOG_TYPE.ALLOW_PARTNER_CONNECTION,
      payload: { phrase },
    });

    this.logger.debug('asked to allow supporting partner in');
  }

  _accept() {
    this.signalling.signalHandshakeAccepted({
      to: ActorType.SUPPORTING_PARTNER,
      secret: this.securityContext.encryptedSupportingSharedSecret(),
    });
  }

  _credsRequested() {
    this.signalling.signalApplicationCredentials({
      application: this.application,
      to: ActorType.SUPPORTING_PARTNER,
      secure: true,
    });
  }

  _completed(application) {
    this.emitter.emit('send-sync-config');
    this.securityContext.supportComplete();
    this.emitter.emit('handshake-complete', application);
    this.logger.debug('handshake completed');
  }

  handle() {
    // Receive a public key from supporting partner and send the secret room
    // through encrypted means. It will only go forward if the customer already
    // joined, otherwise the supporting partner will keep re-sending this signal.
    this.listener.onSupportingPartnerSignal(
      Signals.HANDSHAKE_CONTEXT,
      ({ key, phrase }) => this._context(key, phrase)
    );

    // This signal is wired to a dialog, which once accepted sends the
    // HANDSHAKE_ACCEPTED to the supporting partner
    this.emitter.on('handshake-acceptance', () => this._accept());

    // Once supporting partner joined the room, an application credential
    // request will be received. The partner is always the peer which should
    // share this data
    this.listener.onSupportingPartnerSignal(
      Signals.APPLICATION_CREDENTIALS_REQUEST,
      () => this._credsRequested()
    );

    // Handshake complete, no more actions required
    this.listener.onSupportingPartnerSignal(Signals.HANDSHAKE_COMPLETED, () =>
      this._completed(this.application)
    );
  }
}
